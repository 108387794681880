<script>
import createApiService from "@/apiService";
import {useDisplay} from "vuetify";

export default {
  name: "SelectAccountPage",
  data: () => ({
    onFinding: true,
    onSelectAccount: false,
    accountList: [],
    onEnrolling: false,
    selectedInstagramAccountId: null,
  }),
  methods: {
    enroll() {
      if (this.selectedInstagramAccountId === null) {
        alert('등록할 인스타그램 아이디를 선택해주세요.')
        return;
      }

      this.onEnrolling = true;

      this.api.post(`/api/my-channel?selectedAccountId=${this.selectedInstagramAccountId}`)
          .then((response) => {
            switch (response.data.code) {
              case 'ENROLL_TIMEOUT':
              case 'UNKNOWN_ERROR': {
                alert('문제가 발생했습니다. 처음부터 다시 시도해주세요.')
                break;
              }
              case 'ALREADY_ENROLLED': {
                alert('이미 연동된 계정이 있습니다.\n계정 분석 페이지로 이동할게요.')
                this.$router.push('/my-channel');
                break;
              }
              case 'SUCCESS': {
                this.$router.push('/enroll-my-channel/done');
                break;
              }
            }
          }).catch(() => {
            alert('문제가 발생했습니다. 서비스 센터에 문의해주세요.')
          })
          .finally(() => {
            this.onEnrolling = false;
          })
    },

    listAccount(accessToken) {
      this.api.get(`/api/my-channel/candidate?accessToken=${accessToken}`)
          .then((response) => {
            switch (response.data.code) {
              case 'UNKNOWN_ERROR': {
                alert("알 수 없는 오류가 발생했습니다. 지속적으로 발생 시 서비스 센터에 문의해주세요.")
                this.$router.push('/enroll-my-channel');
                break;
              }
              case 'EMPTY_ACCOUNT_CANDIDATE_LIST': {
                alert("로그인한 페이스북 계정에 연동된 인스타그램 계정이 없습니다. \n가이드를 확인해 계정 연동 후 재시도 부탁드려요.")
                this.$router.push('/enroll-my-channel');
                break;
              }
              case 'SUCCESS': {
                this.accountList = response.data.data;
                this.onFinding = false;
                this.onSelectAccount = true;
                break;
              }
            }
          })
          .catch(() => {
            alert("문제가 발생했습니다. 지속적으로 발생 시 서비스 센터에 문의해주세요.")
            this.$router.push('/enroll-my-channel');
          })
    },
  },
  created() {
    this.api = createApiService(this.$store, this.$router);
    let params = new URLSearchParams(window.location.search);
    let accessToken = params.get('accessToken');
    this.listAccount(accessToken);
  },
  setup() {
    const {smAndDown} = useDisplay()
    return {smAndDown}
  },
}
</script>

<template>
  <v-container fluid>
    <v-row justify="center" align="center" class="h-100">
      <v-col align-self="center" :cols="smAndDown ? 12 : 5">
        <v-container
            v-if="onFinding"
            fluid
        >
          <v-container
              class="fill-height"
          >
            <v-container>
              <v-row align="center" justify="center">
                <v-progress-circular
                    indeterminate
                    :size="128"
                    :width="8"
                ></v-progress-circular>
              </v-row>
              <v-row
                  class="py-8 text-h6"
                  align="center"
                  justify="center"
              >
                계정을 찾는 중입니다.
              </v-row>
            </v-container>
          </v-container>
        </v-container>
        <v-container
            v-if="onSelectAccount"
            fluid
        >
          <v-sheet
              rounded="3"
              class="pb-3"
          >
            <v-card
                rounded="2"
                variant="flat"
                class="pt-10"
            >
              <v-card-title class="font-weight-bold text-h6 text-center" v-if="smAndDown">
                분석 리포트를 확인할<br>
                <span class="text-pink-lighten-3">
                  인스타그램
                </span>
                아이디를 선택해주세요.
              </v-card-title>
              <v-card-title class="font-weight-bold text-h6 text-center" v-else>
                분석 리포트를 확인할<br>
                <span class="text-pink-lighten-3">
                  인스타그램
                </span>
                아이디를 <br>선택해주세요.
              </v-card-title>
            </v-card>
            <v-container>
              <v-list
                  class="my-2"
                  base-color="white"
                  color="white"
                  active-color="white"
              >
                <v-list-item
                    v-for="account in accountList"
                    :key="account"
                    :value="account"
                    @click="this.selectedInstagramAccountId = account.instagramAccountId"
                >
                  <v-card
                      variant="elevated"
                  >
                    <template v-slot:title>
                      <div class="font-weight-bold">
                        {{ account.name }}
                      </div>
                    </template>
                    <template v-slot:prepend>
                      <v-avatar>
                        <v-img
                            :src="account.profilePictureUrl"
                        ></v-img>
                      </v-avatar>
                    </template>
                    <template v-slot:append>
                      <v-icon v-if="this.selectedInstagramAccountId === account.instagramAccountId" color="green">
                        mdi-check
                      </v-icon>
                    </template>
                  </v-card>
                </v-list-item>
              </v-list>
              <v-btn
                  block
                  @click="enroll"
                  :loading="onEnrolling"
              >
                등록하기
              </v-btn>
            </v-container>
          </v-sheet>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.no-background {
  background-color: transparent !important;
}
</style>