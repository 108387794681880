import axios from 'axios';

export default function createApiService(store, router) {
    const api = axios.create({
        baseURL: process.env.VUE_APP_API_BASE_URL,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    api.interceptors.request.use(config => {
        config.headers['Authorization'] = 'Bearer ' + store.state.accessToken;
        return config;
    }, error => {
        return Promise.reject(error);
    });

    api.interceptors.response.use(response => {
        return response;
    },async error => {
        if (error.response.status === 403) {
            try {
                const refreshToken = store.state.refreshToken;
                const response = await api.post('/auth-api/member/refresh-token', { 'refreshToken': refreshToken });
                if (response.status === 200) {
                    store.commit('setAccessToken', response.data);
                    error.config.headers['Authorization'] = 'Bearer ' + response.data;
                    return api.request(error.config);
                }
            } catch (refreshError) {
                alert("로그인이 필요합니다.");
                store.commit('logOut');
                store.commit('openLoginDialog');
                router.push("/");
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    });

    return api;
}
