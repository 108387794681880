<script>
export default {
  name: "PCFeedbackCard",
  props: {
    title: String,
    good: String,
    bad: String,
  },
}
</script>

<template>
  <v-sheet
      rounded="lg"
      border="sm"
      class="pa-6"
  >
    <v-row>
      <v-col>
        <h4 class="text-primary">
          # {{ title }}
        </h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card
            variant="outlined"
            style="border-color: #F9F9F9; background-color: #F9F9F9"
            height="20vh"
        >
          <v-card-title>
            좋은 점
          </v-card-title>
          <v-card-text>
            {{ good }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card
            variant="outlined"
            style="border-color: #F9F9F9; background-color: #F9F9F9"
            height="20vh"
        >
          <v-card-title>
            개선할 점
          </v-card-title>
          <v-card-text>
            {{ bad }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<style scoped>

</style>