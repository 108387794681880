<script>
import {convertDateFormat, formatToPercentage} from "@/utils";
import createApiService from "@/apiService";
import {Line} from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: "MobileContentDetailDialog",
  props: ['contentId'],
  components: { Line },
  data() {
    return {
      recentStats: [],
      serverData: null,
      playCountChartData: null,
      playCountChartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          x: {
            type: 'category',
          },
          y: {
            type: 'linear',
            position: 'left',
          },
          y1: {
            type: 'linear',
            position: 'right',
            grid: {
              drawOnChartArea: false,
            },
          },
        },
      },
    }
  },
  methods: {
    formatToPercentage,
    convertDateFormat,
    loadRecentDailyStatsOfContent(contentId) {
      this.api.get(`/api/my-content/${contentId}`)
          .then((response) => {
            switch (response.data.code) {
              case 'SUCCESS': {
                this.recentStats = response.data.data.dailyContentStatsList
                this.serverData = response.data.data
                this.prepareChartData()
                break;
              }
              case 'CREATOR_NOT_LINKED': {
                alert("연동된 계정이 없습니다.")
                break;
              }
              case 'BEFORE_FIRST_COLLECTION': {
                alert("채널 정보를 수집 중입니다. 잠시 후 다시 시도해주세요.")
                break;
              }
              default: {
                alert("문제가 발생했습니다. 지속적으로 발생 시 서비스 센터에 문의해주세요.")
              }
            }
          })
          .catch(() => {
            alert("문제가 발생했습니다. 지속적으로 발생 시 서비스 센터에 문의해주세요.")
          })
    },
    prepareChartData() {
      const dateList = this.recentStats.map(item => item.date).reverse()
      const playCountList = this.recentStats.map(item => item.playCount).reverse()
      const playCountIncreaseList = this.recentStats.map(item => item.playCountIncrease).reverse()

      this.playCountChartData = {
        labels: dateList,
        datasets: [
          {
            type: 'line',
            label: '총 조회 수',
            backgroundColor: '#FFFFFF',
            borderColor: '#8950FF',
            borderWidth: 1,
            fill: false,
            data: playCountList,
            yAxisID: 'y',
          },
          {
            type: 'bar',
            label: '조회 수 증가',
            backgroundColor: '#8950FF',
            data: playCountIncreaseList,
            barPercentage: 0.5,
            yAxisID: 'y1',
          }
        ]
      }
    }
  },
  created() {
    this.api = createApiService(this.$store, this.$router);
    this.loadRecentDailyStatsOfContent(this.contentId)
  }
}
</script>

<template>
  <v-card
      title="내 릴스 성과 상세"
      subtitle="내 릴스의 성과를 상세하게 확인해보세요!"
  >
    <template v-slot:append>
      <v-btn
          icon="mdi-close"
          @click="$emit('closeDialog')"
          variant="text"
      >
      </v-btn>
    </template>
    <v-card-text class="px-0">
      <v-container v-if="this.playCountChartData !== null" class="mx-1">
        <div class="small-header">
          조회수 추이
        </div>
        <Line :options="playCountChartOptions" :data="playCountChartData"/>
      </v-container>
      <v-container v-if="this.serverData !== null" class="mx-1">
        <div class="small-header">
          조회수 대비 저장&공유 비율
        </div>
        <div>
          내 계정 평균 : {{ formatToPercentage(this.serverData.averageSaveAndShareRatio) }}
        </div>
        <div>
          해당 영상 : {{ formatToPercentage(this.serverData.saveAndShareRatio) }}
        </div>
      </v-container>
      <v-container class="mx-1">
        <div class="small-header">
          지표 추이
        </div>
        <v-table density="compact">
          <thead class="bg-grey-lighten-3">
          <tr>
            <th class="text-center">
              <v-icon>mdi-calendar</v-icon>
            </th>
            <th class="text-center">
              <v-icon>mdi-play-circle-outline</v-icon>
            </th>
            <th class="text-center">
              <v-icon>mdi-heart-outline</v-icon>
            </th>
            <th class="text-center">
              <v-icon>mdi-comment-outline</v-icon>
            </th>
            <th class="text-center">
              <v-icon>mdi-share-outline</v-icon>
            </th>
            <th class="text-center">
              <v-icon>mdi-bookmark-outline</v-icon>
            </th>
            <!--          <th class="text-center">-->
            <!--            <v-icon>mdi-account-multiple-outline</v-icon>-->
            <!--          </th>-->
          </tr>
          </thead>
          <tbody>
          <tr v-for="stat in recentStats" :key="stat">
            <td class="text-center"> {{ convertDateFormat(stat.date) }}</td>
            <td class="text-center">
              <span class="stat-container">
                {{ stat.playCount.toLocaleString() }}
                <v-badge
                    v-if="stat.playCountIncrease != null"
                    color="green"
                    :content="stat.playCountIncrease"
                    inline
                    style="vertical-align: middle"
                />
              </span>
            </td>
            <td class="text-center">
              <span class="stat-container">
                {{ stat.likeCount.toLocaleString() }}
                <v-badge
                    v-if="stat.likeCountIncrease != null"
                    color="green"
                    :content="stat.likeCountIncrease"
                    inline
                    style="vertical-align: middle"
                />
              </span>
            </td>
            <td class="text-center">
              <span class="stat-container">
                {{ stat.commentCount.toLocaleString() }}
                <v-badge
                    v-if="stat.commentCountIncrease != null"
                    color="green"
                    :content="stat.commentCountIncrease"
                    inline
                    style="vertical-align: middle"
                />
              </span>
            </td>
            <td class="text-center">
              <span class="stat-container">
              {{ stat.shareCount.toLocaleString() }}
              <v-badge
                  v-if="stat.shareCountIncrease != null"
                  color="green"
                  :content="stat.shareCountIncrease"
                  inline
                  style="vertical-align: middle"
              />
              </span>
            </td>
            <td class="text-center">
              <span class="stat-container">
                {{ stat.saveCount.toLocaleString() }}
                <v-badge
                    v-if="stat.saveCountIncrease != null"
                    color="green"
                    :content="stat.saveCountIncrease"
                    inline
                    style="vertical-align: middle"
                />
              </span>
            </td>
          </tr>
          </tbody>
        </v-table>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.stat-container {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.small-header {
  font-size: 18px;
  font-weight: bold;
}
</style>