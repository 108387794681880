import {createApp} from 'vue'
import axios from 'axios'

import App from './App.vue'
import router from './router'
import store from './store'

// Vuetify
import 'vuetify/styles'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {aliases, mdi} from 'vuetify/iconsets/mdi'


const app = createApp(App)

const newShortBoostTheme = {
    dark: false,
    colors: {
        background: '#FFFFFF',
        'background-grey': '#F9F9F9',
        surface: '#FFFFFF',
        'surface-bright': '#FFFFFF',
        'surface-light': '#EEEEEE',
        'surface-variant': '#424242',
        'on-surface-variant': '#EEEEEE',
        primary: '#8950FF',
        secondary: '#48A9A6',
        'text-primary': '#585858',
        'text-secondary': '#9B9B9B',
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
    },
    variables: {
        'border-color': '#000000',
        'border-opacity': 0.12,
        'high-emphasis-opacity': 0.87,
        'medium-emphasis-opacity': 0.60,
        'disabled-opacity': 0.38,
        'idle-opacity': 0.04,
        'hover-opacity': 0.04,
        'focus-opacity': 0.12,
        'selected-opacity': 0.08,
        'activated-opacity': 0.12,
        'pressed-opacity': 0.12,
        'dragged-opacity': 0.08,
        'theme-kbd': '#212529',
        'theme-on-kbd': '#FFFFFF',
        'theme-code': '#F5F5F5',
        'theme-on-code': '#000000',
    }
}


const vuetify = createVuetify({
    theme: {
        defaultTheme: 'newShortBoostTheme',
        themes: {
            newShortBoostTheme: newShortBoostTheme,
        },
    },
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        },
    },
    components,
    directives,
})

app.config.globalProperties.$axios = axios
app.use(store).use(vuetify).use(router).mount('#app')
