<script>

import {formatNumber} from "@/utils";

export default {
  name: "MobileTopContentCard",
  methods: {formatNumber},
  props: ['yesterdayHotContentList'],
}
</script>

<template>
  <v-card variant="flat" class="px-2">
    <v-row class="align-center">
      <v-col cols="auto">
        <v-card-title class="text-h6">
          내 릴스 인기순위
        </v-card-title>
      </v-col>
    </v-row>
    <v-card-text>
      <v-row
          v-for="(content, idx) in yesterdayHotContentList" :key="content.id" align="center"
          @click="$emit('open-content-detail-dialog', content.id)"
      >
        <v-col cols="7">
          <v-img
              id="content-image"
              :src="content.thumbnailUrl"
              style="border-radius: 2%; aspect-ratio: 9 / 16;"
              class="clickable"
              cover
          >
            <v-container
                class="overlay-top"
            >
              <v-row no-gutters justify="end">
                <v-col cols="auto">
                  {{ content.uploadedDate }}
                </v-col>
              </v-row>
            </v-container>
            <v-container
                class="overlay-bottom"
            >
              <v-row no-gutters>
                <v-col cols="auto">
                  <v-icon>
                    mdi-play
                  </v-icon>
                </v-col>
                <v-col cols="auto">
                  {{ formatNumber(content.playCount) }}
                </v-col>
              </v-row>
            </v-container>
          </v-img>
        </v-col>
        <v-col cols="5">
          <div class="mt-2 d-flex">
            <span v-if="idx === 0" class="text-h6">
              🥇
            </span>
            <span v-else-if="idx === 1" class="text-h6">
              🥈
            </span>
            <span v-else class="text-h6">
              🥉
            </span>
            <span class="align-content-center pl-2">
              조회수 +{{formatNumber(content.playCountIncreaseCount)}}
            </span>
          </div>
          <v-card
              variant="flat"
          >
            <v-card-item class="px-0 py-2">
              <label
                  for="followCount"
                  class="text-text-secondary"
                  style="font-size: 14px"
              >
                좋아요
              </label>
              <v-text-field
                  id="followCount"
                  variant="outlined"
                  density="compact"
                  base-color="text-secondary"
                  readonly
                  hide-details
              >
                {{ formatNumber(content.playCount) }}
              </v-text-field>
            </v-card-item>
            <v-card-item class="px-0 py-2">
              <label
                  for="followCount"
                  class="text-text-secondary"
                  style="font-size: 14px"
              >
                댓글
              </label>
              <v-text-field
                  id="followCount"
                  variant="outlined"
                  density="compact"
                  base-color="text-secondary"
                  readonly
                  hide-details
              >
                {{ formatNumber(content.playCount) }}
              </v-text-field>
            </v-card-item>
            <v-card-item class="px-0 py-2">
              <label
                  for="followCount"
                  class="text-text-secondary"
                  style="font-size: 14px"
              >
                저장 공유 비율
              </label>
              <v-text-field
                  id="followCount"
                  variant="outlined"
                  density="compact"
                  base-color="text-secondary"
                  readonly
                  hide-details
              >
                {{ formatNumber(content.playCount) }}
              </v-text-field>
            </v-card-item>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.overlay-bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  font-weight: bold;
  transform: translateX(-50%);
  color: white;
  padding: 8px;
  border-radius: 5px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent); /* 그라데이션 효과 */
}

.overlay-top {
  position: absolute;
  top: 0;
  left: 50%;
  font-weight: bold;
  transform: translateX(-50%);
  color: white;
  padding: 8px;
  border-radius: 5px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), transparent); /* 그라데이션 효과 */
}

.text-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.clickable {
  cursor: pointer;
}

.image-container {
  position: relative;
  overflow: hidden;
  aspect-ratio: 9 / 16;
  border-radius: 2%;
}

</style>