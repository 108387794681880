<script>
import {openUrlInNewTab} from "@/utils";

export default {
  name: "UsageLimitDialogCard",
  methods: {openUrlInNewTab}
}
</script>

<template>
  <v-card
      min-width="20vw"
      title="사용량 초과"
  >
    <v-card-text>
      이번 달 사용량이 초과되었습니다.
      <br>
      추가 사용을 신청해주세요.
    </v-card-text>
    <template v-slot:append>
      <v-btn
          icon="mdi-close"
          @click="$emit('closeUsageLimitDialog')"
          variant="text"
      >
      </v-btn>
    </template>
    <template v-slot:actions>
      <v-btn
          block
          class="ms-auto"
          color="primary"
          to="/membership/subscribe"
      >
        멤버십 변경하기
      </v-btn>
    </template>
  </v-card>
</template>

<style scoped>

</style>