<script>
import {formatNumber, openUrlInNewTab} from "@/utils";
import createApiService from "@/apiService";

export default {
  name: "MobileContentDetailDialog",
  methods: {
    formatNumber,
    openUrlInNewTab,
    loadContentDetail() {
      this.api.get(`/api/hot-short-form/content/${this.contentId}`)
          .then((response) => {
            this.contentDetail = response.data.data;
          })
          .catch(() => {
            alert('문제가 발생했습니다. 다시 시도해주세요.');
            this.$emit('close-content-detail-dialog');
          });
    },
  },
  props: ['contentId'],
  data: () => ({
    showOriginalScript: true,
    showOriginalCaption: true,
    contentDetail: null,
  }),
  computed: {
    displayNarration() {
      if (this.contentDetail.language === 'KOREAN') {
        if (this.contentDetail.script === null) {
          return '나레이션이 없거나, 숏부스트 AI가 수집 전이에요.'
        } else return this.contentDetail.script
      } else {
        if (this.contentDetail.translatedScript === null) {
          return '나레이션이 없거나, 숏부스트 AI가 수집 전이에요.'
        }
        return this.contentDetail.translatedScript
      }
    },
    displayCaption() {
      if (this.contentDetail.language === 'KOREAN') {
        if (this.contentDetail.caption === null) {
          return '캡션이 없어요!'
        } else return this.contentDetail.caption
      } else {
        if (this.contentDetail.translatedCaption === null) {
          return '캡션이 없어요!'
        }
        return this.contentDetail.translatedCaption
      }
    },
    displayTopic() {
      if (this.contentDetail.topic === null) {
        return 'AI가 아직 주제를 파악하지 못했어요.'
      } else {
        return `주제 : ${this.contentDetail.topic}`
      }
    }
  },
  created() {
    this.api = createApiService(this.$store, this.$router);
    this.loadContentDetail()
  }
}
</script>

<template>
  <v-card>
    <v-card-title>
      <v-row align="end" justify="end" class="bg-white" dense>
        <v-col>
          <v-btn
              icon="mdi-close"
              @click="$emit('close-content-detail-dialog')"
              variant="text"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="this.contentDetail !== null">
      <v-row align="center" class="py-2">
        <v-col class="font-weight-bold" style="font-size:1.1rem">
          {{ displayTopic }}
        </v-col>
      </v-row>
      <v-row
          class="px-1"
          align="center"
      >
        <v-img
            id="content-image"
            :src="contentDetail.thumbnailUrl"
            style="border-radius: 2%; aspect-ratio: 9 / 16"
            class="clickable"
            cover
            @click="openUrlInNewTab(contentDetail.contentUrl)"
        >
          <v-container
              class="overlay-top"
          >
            <v-row no-gutters justify="end">
              <v-col cols="auto">
                {{ contentDetail.uploadedDate }}
              </v-col>
            </v-row>
          </v-container>
          <v-container
              class="overlay-bottom"
          >
            <v-row no-gutters>
              <v-col cols="auto">
                <v-icon>
                  mdi-play
                </v-icon>
              </v-col>
              <v-col cols="auto">
                {{ formatNumber(contentDetail.playCount) }}
              </v-col>
            </v-row>
          </v-container>
        </v-img>
      </v-row>
      <v-row class="px-1 pt-4">
        <v-textarea
            label="캡션"
            readonly
            auto-grow
            rows="3"
            block
            variant="outlined"
            hide-details
            no-resize
            :model-value="this.displayCaption"
        >
        </v-textarea>
      </v-row>
      <v-row class="px-1 pt-4 pb-5">
        <v-textarea
            label="나레이션"
            readonly
            auto-grow
            rows="3"
            block
            variant="outlined"
            hide-details
            no-resize
            :model-value="this.displayNarration"
        >
        </v-textarea>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.overlay-bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  font-weight: bold;
  transform: translateX(-50%);
  color: white;
  padding: 8px;
  border-radius: 5px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent); /* 그라데이션 효과 */
}

.overlay-top {
  position: absolute;
  top: 0;
  left: 50%;
  font-weight: bold;
  transform: translateX(-50%);
  color: white;
  padding: 8px;
  border-radius: 5px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), transparent); /* 그라데이션 효과 */
}

</style>