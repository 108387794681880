<script>
export default {
  name: "UserInputDialog",
  props: ['userInput'],
}
</script>

<template>
  <v-card variant="flat" class="pb-3">
    <v-card-title class="mx-2">
      <v-row align="center" justify="center" class="bg-white">
        <v-col class="text-end">
          <v-btn
              size="large"
              icon="mdi-close"
              @click="$emit('closeDialog')"
              variant="text"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      {{ userInput }}
    </v-card-text>
  </v-card>

</template>

<style scoped>
</style>