<script>
import {useDisplay} from "vuetify";
import createApiService from "@/apiService";
import AILoadingPage from "@/components/ai/AILoadingPage.vue";
import MobileFeedbackCard from "@/components/ai/MobileFeedbackCard.vue";
import PCFeedbackCard from "@/components/ai/PCFeedbackCard.vue";
import {openUrlInNewTab} from "@/utils";
import UsageLimitDialogCard from "@/components/ai/UsageLimitDialogCard.vue";
import UserInputDialog from "@/components/ai/UserInputDialog.vue";

const PageStatus = {
  PROCESSING: 'processing',
  SUCCESS: 'success'
}

export default {
  name: "ScriptFeedbackAIPage",
  components: {UserInputDialog, UsageLimitDialogCard, AILoadingPage, MobileFeedbackCard, PCFeedbackCard},
  setup() {
    const {smAndDown, smAndUp} = useDisplay()
    return {smAndDown, smAndUp}
  },
  data: () => ({
    PageStatus,
    pageStatus: PageStatus.PROCESSING,
    usageLimitDialog: false,
    inputDialog: false,
    uuid: null,
    pullingId: null,
    pullingCount: 0,
    maxPullingCount: 60,
    feedback: null,
  }),
  methods: {
    openUrlInNewTab,
    getScriptFeedback() {
      this.pullingCount++
      if (this.pullingCount > this.maxPullingCount) {
        this.pullingCount = 0;
        clearInterval(this.pullingId);
        alert("문제가 발생했습니다. 지속적으로 발생 시 서비스 센터에 문의해주세요.")
        this.$router.push('/?type=script-feedback-ai');
      }
      this.api.get(`/api/ai/script-feedback/${this.uuid}`)
          .then((response) => {
            if (response.data.data.status === 'COMPLETED') {
              this.feedback = response.data.data
              this.pageStatus = PageStatus.SUCCESS;
              clearInterval(this.pullingId);
            } else if (response.data.data.status === 'FAILED') {
              this.pullingCount = 0;
              clearInterval(this.pullingId);
              alert("문제가 발생했습니다. 지속적으로 발생 시 서비스 센터에 문의해주세요.")
              this.$router.push('/?type=script-feedback-ai');
            }
          })
          .catch(() => {
            this.pullingCount = 0;
            clearInterval(this.pullingId);
            alert("문제가 발생했습니다. 지속적으로 발생 시 서비스 센터에 문의해주세요.")
            this.$router.push('/?type=script-feedback-ai');
          });
    },
    receiveFeedbackAgain() {
      this.pageStatus = PageStatus.PROCESSING;
      const body = {script: this.feedback.userInput}
      this.api.post("/api/ai/script-feedback", body)
          .then((response) => {
            switch (response.data.code) {
              case 'EXCEEDED_USAGE_LIMIT': {
                this.pageStatus = PageStatus.SUCCESS;
                this.usageLimitDialog = true;
                break;
              }
              case 'SUCCESS': {
                let uuid = response.data.data.uuid;
                this.$router.push({name: 'ScriptFeedbackAIPage', query: {"uuid": uuid}})
                break;
              }
              default: {
                alert("문제가 발생했습니다. 지속적으로 발생 시 서비스 센터에 문의해주세요.")
                this.$router.push('/?type=script-feedback-ai');
                break;
              }
            }
          }).catch(() => {
            alert("문제가 발생했습니다. 서비스 센터에 문의해주세요.")
          });
    },
    feedbackWithNewScript(){
      this.$router.push('/?type=script-feedback-ai');
    }
  },
  watch: {
    '$route.query.uuid'(uuid) {
      if (uuid) {
        this.uuid = uuid;
        this.feedback = null;
        this.pageStatus = PageStatus.PROCESSING;
        this.pullingId = null;
        this.pullingCount = 0;
        this.pullingId = setInterval(() => {
          this.getScriptFeedback()
        }, 1000)
      }
    }
  },
  beforeCreate() {
    const uuid = this.$route.query.uuid;
    if (!uuid) {
      this.$router.push('/?type=script-feedback-ai');
    }
  },
  created() {
    const uuid = this.$route.query.uuid;
    this.uuid = uuid;
    this.api = createApiService(this.$store, this.$router);
    this.pullingId = setInterval(() => {
      this.getScriptFeedback()
    }, 1000)
  },
  beforeUpdate() {
    const uuid = this.$route.query.uuid;
    if (!uuid) {
      this.$router.push('/?type=script-feedback-ai');
    }
  },
  beforeUnmount() {
    if (this.pullingId) {
      clearInterval(this.pullingId);
    }
  }
}
</script>

<template>
  <v-container
      :class="smAndUp ? 'px-10 pt-10 fill-height' : 'px-5 pt-5 fill-height'"
      v-if="pageStatus === PageStatus.PROCESSING"
      fluid="true"
      fill-height
  >
    <AILoadingPage/>
  </v-container>
  <v-container
      fluid="true"
      :class="smAndUp ? 'px-10 pt-10' : 'px-5 pt-5'"
      v-if="pageStatus === PageStatus.SUCCESS"
  >
    <v-row @click="openUrlInNewTab('https://bit.ly/3Wj9gDf')" class="clickable">
      <v-col v-if="smAndUp" cols="6">
        <v-img :src="require('@/assets/pc_banner.png')"></v-img>
      </v-col>
      <v-col v-else>
        <v-img :src="require('@/assets/mobile_banner.png')"></v-img>
      </v-col>
    </v-row>
    <v-row
        class="text-h5"
        align="center"
        justify="center"
    >
      <v-col v-if="smAndDown" cols="auto">
        숏부스트 AI가 <br> 대본을 분석한 결과에요
      </v-col>
      <v-col v-else cols="auto">
        숏부스트 AI가 대본을 분석한 결과에요
      </v-col>
      <v-col class="text-end">
        <v-btn color="primary" @click="inputDialog=true">
          입력값 보기
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="my-5"/>
    <v-row
        v-if="smAndDown"
        align="center"
        justify="center"
    >
      <v-col cols="12">
        <MobileFeedbackCard
            title="주제 피드백"
            :good=this.feedback.topicGood
            :bad=this.feedback.topicBad
        />
      </v-col>
      <v-col cols="12">
        <MobileFeedbackCard
            title="후킹 멘트 피드백"
            :good=this.feedback.hookGood
            :bad=this.feedback.hookBad
        />
      </v-col>
      <v-col cols="12">
        <MobileFeedbackCard
            title="내용 구성 피드백"
            :good=this.feedback.contentGood
            :bad=this.feedback.contentBad
        />
      </v-col>
      <v-col>
        <v-row>
          <v-col cols="12">
            <v-btn color="text-secondary" size="large" block="true" @click="receiveFeedbackAgain">
              같은 대본으로 다시 피드백
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn color="primary" size="large" block="true" @click="receiveFeedbackAgain">
              다른 대본으로 피드백 받기
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
        v-else
        align="center"
        justify="center"
        class="py-8"
    >
      <v-col cols="4">
        <PCFeedbackCard
            title="주제 피드백"
            :good=this.feedback.topicGood
            :bad=this.feedback.topicBad
        />
      </v-col>
      <v-col cols="4">
        <PCFeedbackCard
            title="후킹 멘트 피드백"
            :good=this.feedback.hookGood
            :bad=this.feedback.hookBad
        />
      </v-col>
      <v-col cols="4">
        <PCFeedbackCard
            title="내용 구성 피드백"
            :good=this.feedback.contentGood
            :bad=this.feedback.contentBad
        />
      </v-col>
      <v-container>
        <v-row align="center" justify="center">
          <v-col cols="3" align-self="center" class="text-center">
            <v-btn block="true" color="text-secondary" size="large" @click="receiveFeedbackAgain">
              같은 대본으로 다시 피드백
            </v-btn>
          </v-col>
          <v-col cols="3" align-self="center" class="text-center">
            <v-btn block="true" color="primary" size="large" @click="feedbackWithNewScript">
              다른 대본으로 피드백 받기
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
  <v-dialog
      v-model="usageLimitDialog"
      width="auto"
  >
    <UsageLimitDialogCard @close-usage-limit-dialog="usageLimitDialog=false"/>
  </v-dialog>
  <v-dialog
      v-model="inputDialog"
      :width="smAndUp ? '40vw' : '100vw'"
  >
    <UserInputDialog :user-input="feedback.userInput" @close-dialog="inputDialog=false"/>
  </v-dialog>
</template>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>