<script>
import PCMembershipLevelCard from "@/components/membership/subscribe/pc/PCLevelCard.vue";
import {generateRandomString} from "@/utils";
import PCMembershipBenefitTable from "@/components/membership/subscribe/pc/PCBenefitTable.vue";
import {useDisplay} from "vuetify";
import MobileMembershipLevelCard from "@/components/membership/subscribe/mobile/MobileLevelCard.vue";
import createApiService from "@/apiService";

export default {
  name: "MembershipSubscribePage",
  components: {MobileMembershipLevelCard, PCMembershipBenefitTable, PCMembershipLevelCard},
  methods: {
    subscribe(membershipLevel) {
      const tossPayments = window.TossPayments(this.clientKey);
      const customerKey = generateRandomString();
      // https://docs.tosspayments.com/guides/billing/integration#requestbillingauth%EC%B9%B4%EB%93%9C-%EC%9D%B4%ED%95%B4%ED%95%98%EA%B8%B0
      tossPayments.requestBillingAuth('카드', {
        customerKey: customerKey,
        successUrl: `${this.appUrl}/membership/process?plan=${membershipLevel}&type=subscribe`,
        failUrl: `${this.appUrl}/membership/subscribe?status=failure`,
      })
          .catch(function (error) {
            if (error.code === 'USER_CANCEL') {
              alert("카드 등록을 취소하셨습니다.")
            }
          });
    },
    downgrade(membershipLevel) {
      if (confirm('멤버십 레벨을 낮추시겠습니까? \n다음 달부터 변경된 멤버십 레벨이 적용됩니다.')) {
        this.$router.push('/membership/process?plan=' + membershipLevel + '&type=downgrade')
      }
    },
    upgrade(membershipLevel) {
      if (confirm('멤버십 레벨을 높이시겠습니까? \n남은 기간 만큼 추가 결제가 진행되고, \n멤버십 레벨이 즉시 업그레이드 됩니다.')) {
        this.$router.push('/membership/process?plan=' + membershipLevel + '&type=upgrade')
      }
    },
    getMembershipInfo() {
      this.api.get("/api/membership/my")
          .then((response) => { this.currentMembershipPlan = response.data.data.planName })
    },
  },
  setup() {
    const {smAndDown, smAndUp} = useDisplay()
    return {smAndDown, smAndUp}
  },
  data() {
    return {
      appUrl: process.env.VUE_APP_URL,
      clientKey: process.env.VUE_APP_TOSS_CLIENT_KEY,
      currentMembershipPlan: '',
      membershipList: [
        {
          'name': 'Free',
          'level': 0,
          'description': '무료 이용',
          'price': '무료',
          'pricePostfix': '',
          'benefits': [
            {
              'name': '숏부스트 AI 기능',
              'subtitleList': ['주제 추천 AI', '후킹 멘트 AI', '대본 피드백 AI'],
              'justUseBoolean': false,
              'descriptionValue': '30회 / 월'
            },
            {
              'name': '크리에이터 정보 탐색',
              'subtitleList': ['데이터 분석', '영상 모아보기'],
              'justUseBoolean': true,
              'descriptionValue': false
            },
            {
              'name': '레퍼런스 영상 찾기',
              'subtitleList': ['키워드로 영상 탐색', '카테고리로 영상 탐색', '나레이션 유/무 설정 가능', '해외 영상 번역 대본 확인', '국내/해외 영상 탐색 가능'],
              'justUseBoolean': true,
              'descriptionValue': true
            }
          ]
        },
        {
          'name': 'Basic',
          'level': 1,
          'description': '기본',
          'price': '19,900',
          'pricePostfix': '원/달',
          'benefits': [
            {
              'name': '숏부스트 AI 기능',
              'subtitleList': ['주제 추천 AI', '후킹 멘트 AI', '대본 피드백 AI'],
              'justUseBoolean': false,
              'descriptionValue': '200회 / 월'
            },
            {
              'name': '크리에이터 정보 탐색',
              'subtitleList': ['데이터 분석', '영상 모아보기'],
              'justUseBoolean': true,
              'descriptionValue': true
            },
            {
              'name': '레퍼런스 영상 찾기',
              'subtitleList': ['키워드로 영상 탐색', '카테고리로 영상 탐색', '나레이션 유/무 설정 가능', '해외 영상 번역 대본 확인', '국내/해외 영상 탐색 가능'],
              'justUseBoolean': true,
              'descriptionValue': true
            }
          ]
        },
        {
          'name': 'Plus',
          'level': 2,
          'description': '플러스',
          'price': '49,900',
          'pricePostfix': '원/달',
          'benefits': [
            {
              'name': '숏부스트 AI 기능',
              'subtitleList': ['주제 추천 AI', '후킹 멘트 AI', '대본 피드백 AI'],
              'justUseBoolean': false,
              'descriptionValue': '500회 / 월'
            },
            {
              'name': '크리에이터 정보 탐색',
              'subtitleList': ['데이터 분석', '영상 모아보기'],
              'justUseBoolean': true,
              'descriptionValue': true
            },
            {
              'name': '레퍼런스 영상 찾기',
              'subtitleList': ['키워드로 영상 탐색', '카테고리로 영상 탐색', '나레이션 유/무 설정 가능', '해외 영상 번역 대본 확인', '국내/해외 영상 탐색 가능'],
              'justUseBoolean': true,
              'descriptionValue': true
            }
          ]
        },
        {
          'name': 'Premium',
          'level': 3,
          'description': '프리미엄',
          'price': '99,900',
          'pricePostfix': '원/달',
          'benefits': [
            {
              'name': '숏부스트 AI 기능',
              'subtitleList': ['주제 추천 AI', '후킹 멘트 AI', '대본 피드백 AI'],
              'justUseBoolean': false,
              'descriptionValue': '1000회 / 월'
            },
            {
              'name': '크리에이터 정보 탐색',
              'subtitleList': ['데이터 분석', '영상 모아보기'],
              'justUseBoolean': true,
              'descriptionValue': true
            },
            {
              'name': '레퍼런스 영상 찾기',
              'subtitleList': ['키워드로 영상 탐색', '카테고리로 영상 탐색', '나레이션 유/무 설정 가능', '해외 영상 번역 대본 확인', '국내/해외 영상 탐색 가능'],
              'justUseBoolean': true,
              'descriptionValue': true
            }
          ]
        },
      ],
    }
  },
  mounted() {
    // 쿼리 파라미터의 status를 읽고, 이게 failure면, alert를 띄워준다.
    if (this.$route.query.status === 'failure') {
      this.$route.query.message ? alert(this.$route.query.message) : alert('카드 등록에 실패했습니다. 다시 시도해주세요')
    }
    // 쿼리 파라미터들 날리기
    this.$router.replace({query: {}})
  },

  created() {
    this.api = createApiService(this.$store, this.$router);
    this.getMembershipInfo()
  },
  computed: {
    currentMembershipLevel() {
      const membershipLevel = this.membershipList.find(membership => membership.name === this.currentMembershipPlan)
      if (!membershipLevel) {
        return -1
      } else {
        return membershipLevel.level
      }
    }
  }
}
</script>

<template>
  <!--  모바일-->
  <v-container
      v-if="smAndDown"
      class='pa-5'
  >
    <v-col v-for="membership in membershipList" :key="membership" col="12">
      <MobileMembershipLevelCard
          :membership-level="membership"
          :current-membership-level="currentMembershipLevel"
          @subscribe="subscribe(membership.name)"
          @downgrade="downgrade(membership.name)"
          @upgrade="upgrade(membership.name)"
      />
    </v-col>
  </v-container>
  <!--  PC-->
  <v-container
      v-else
      class="pa-10 my-10"
  >
    <v-container/> <!--  비율이 이상해서 적당히 패딩 TODO : FE를 잘하게 되면 바꾸기-->
    <v-row>
      <v-col v-for="membership in membershipList" :key="membership" col="3">
        <PCMembershipLevelCard
            :membership-level="membership"
            :current-membership-level="currentMembershipLevel"
            @subscribe="subscribe(membership.name)"
            @downgrade="downgrade(membership.name)"
            @upgrade="upgrade(membership.name)"
        />
      </v-col>
    </v-row>
    <v-row class="pt-3">
      <PCMembershipBenefitTable
          :membership-list="membershipList"
      />
    </v-row>
  </v-container>
</template>

<style scoped>

</style>