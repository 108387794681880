<script>
import {useDisplay} from 'vuetify'
import createApiService from "@/apiService";
import MobileContentCard from "@/components/hotshortform/MobileContentCard.vue";
import PCContentCard from "@/components/hotshortform/PCContentCard.vue";
import {openUrlInNewTab} from "@/utils";
import PCCreatorDetailDialog from "@/components/hotshortform/PCCreatorDetailDialog.vue";
import MobileCreatorDetailDialog from "@/components/hotshortform/MobileCreatorDetailDialog.vue";
import CreatorDetailUnavailableNoticeDialogCard
  from "@/components/hotshortform/CreatorDetailUnavailableNoticeDialogCard.vue";
import PCContentDetailDialog from "@/components/hotshortform/PCContentDetailDialog.vue";
import MobileContentDetailDialog from "@/components/hotshortform/MobileContentDetailDialog.vue";

let today = new Date().toISOString().split('T')[0];
let sixMonthAgo = new Date().setDate(new Date().getDate() - 180);
let formattedSixMonthAgo = new Date(sixMonthAgo).toISOString().split('T')[0];

export default {
  name: "NewContentSearchPage",
  components: {
    MobileContentDetailDialog,
    CreatorDetailUnavailableNoticeDialogCard,
    MobileCreatorDetailDialog,
    PCCreatorDetailDialog,
    MobileContentCard,
    PCContentCard,
    PCContentDetailDialog,
  },
  setup() {
    const {smAndDown, smAndUp} = useDisplay()
    return {smAndDown, smAndUp}
  },
  data: () => ({
    pcSearchOptionMenu: false,
    mobileSearchDialogOn: false,
    showMobileCreatorDetailDialog: false,
    showPcCreatorDetailDialog: false,
    showMobileContentDetailDialog: false,
    showPcContentDetailDialog: false,

    showCreatorDetailUnavailableDialog: false,

    creatorDetailInfo: null,

    categoryList: [
      {'name': 'ALL', 'description': '전체'},
      {'name': 'BEAUTY', 'description': '뷰티/미용'},
      {'name': 'LIVING', 'description': '생활/리빙'},
      {'name': 'FOOD', 'description': '음식/맛집'},
      {'name': 'FASHION', 'description': '패션'},
      {'name': 'PARENTING', 'description': '육아/아동'},
      {'name': 'BUSINESS', 'description': '비지니스'},
      {'name': 'TRAVEL', 'description': '여행'},
      {'name': 'DAILY', 'description': '일상'},
      {'name': 'DIGITAL', 'description': '디지털/IT'},
      {'name': 'BOOK', 'description': '도서'},
      {'name': 'EDUCATION', 'description': '교육'},
      {'name': 'BROADCAST', 'description': '방송/연예'},
      {'name': 'INFORMATION', 'description': '정보전달'},
      {'name': 'GAME', 'description': '게임'},
      {'name': 'HEALTH_SPORTS', 'description': '헬스/스포츠'},
      {'name': 'PET', 'description': '반려동물'},
    ],

    searchTerm: null,
    contentList: [],
    contentLoading: false,
    loadMoreEnabled: true,
    zeroResult: false,
    currentPageNo: 1,
    searchCommand: {
      selectedCategory: 'ALL',
      startDate: formattedSixMonthAgo,
      endDate: today,
      includeForeign: true,
      onlyNarrated: false,
    },
    openTargetContentId: null,
  }),
  methods: {
    openUrlInNewTab,
    openPCContentDetailDialog(contentId) {
      this.openTargetContentId = contentId;
      this.showPcContentDetailDialog = true;
    },
    openMobileContentDetailDialog(contentId) {
      this.openTargetContentId = contentId;
      this.showMobileContentDetailDialog = true;
    },
    openPCCreatorDetailDialog(creatorId) {
      this.api.get(`/api/hot-short-form/creator/${creatorId}`)
          .then((response) => {
            switch (response.data.code) {
              case 'ROW_MEMBERSHIP_LEVEL': {
                this.showCreatorDetailUnavailableDialog = true;
                break;
              }
              case 'SUCCESS': {
                this.creatorDetailInfo = response.data.data;
                this.showPcCreatorDetailDialog = true;
              }
            }
          })
    },
    openMobileCreatorDetailDialog(creatorId) {
      this.api.get(`/api/hot-short-form/creator/${creatorId}`)
          .then((response) => {
            switch (response.data.code) {
              case 'ROW_MEMBERSHIP_LEVEL': {
                this.showCreatorDetailUnavailableDialog = true;
                break;
              }
              case 'SUCCESS': {
                this.creatorDetailInfo = response.data.data;
                this.showMobileCreatorDetailDialog = true;
              }
            }
          })
    },
    listContent() {
      if (this.searchTerm != null && this.searchTerm.length > 20) {
        alert("검색어는 30자 이내로 입력해주세요.")
        return;
      }
      if (this.searchTerm != null && this.searchTerm.trim().includes(" ")) {
        alert("검색어는 한 단어로 입력해주세요.")
        return;
      }

      if (this.contentLoading) {
        return;
      }
      this.zeroResult = false;
      this.contentLoading = true;

      let category
      if (this.searchCommand.selectedCategory === 'ALL') {
        category = null
      } else {
        category = this.searchCommand.selectedCategory
      }
      this.api.get('/api/hot-short-form/content/search', {
        params: {
          category: category,
          searchTerm: this.searchTerm,
          startDate: this.searchCommand.startDate,
          endDate: this.searchCommand.endDate,
          includeForeign: this.searchCommand.includeForeign,
          onlyNarrated: this.searchCommand.onlyNarrated,
          pageNo: this.currentPageNo
        }
      }).then((response) => {
        if (response.data.data.length < 12) {
          this.loadMoreEnabled = false;
        }

        if (response.data.data.length === 0 && this.currentPageNo === 1) {
          this.zeroResult = true;
        }
        response.data.data.forEach((content) => {
          this.contentList.push(content)
        })
      }).finally(() => {
        this.contentLoading = false;
      })
    },
    search() {
      this.loadMoreEnabled = true;
      this.currentPageNo = 1;
      this.contentList = [];
      this.listContent();
    },
    toTopicSuggestion() {
      this.$router.push('/?type=topic-suggestion-ai');
    },
    loadNextPage() {
      if (this.contentLoading) return;
      this.currentPageNo += 1;
      this.listContent();
    },
    viewWithSearchOption() {
      this.pcSearchOptionMenu = false;
      this.contentList = [];
      this.mobileSearchDialogOn = false;
      this.contentLoading = false;
      this.loadMoreEnabled = true;
      this.zeroResult = false;
      this.currentPageNo = 1;
      this.listContent();
    }
  },
  created() {
    this.api = createApiService(this.$store, this.$router);
    if (this.$route.query.category) {
      this.searchCommand.selectedCategory = this.$route.query.category;
      this.listContent();
    }
    if (this.$route.query.searchTerm === undefined || this.$route.query.searchTerm === '') {
      this.searchTerm = null;
    } else {
      this.searchTerm = this.$route.query.searchTerm;
      let url = new URL(window.location.href);
      url.searchParams.delete('searchTerm');
      window.history.pushState({}, '', url);
      this.listContent();
    }
  },
  mounted() {
    this.observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && this.loadMoreEnabled) {
        this.loadNextPage();
      }
    });
    this.observer.observe(this.$refs.loadMoreRef);
  },
  watch: {
    // 텅으로 다녀와도 무한 스크롤 되도록 설정
    loadMoreEnabled() {
      this.$nextTick(() => {
        if (this.loadMoreEnabled) {
          this.observer.observe(this.$refs.loadMoreRef);
        }
      });
    },
  },
}
</script>

<template>
  <v-container
      fluid
      :class="smAndUp ? 'px-10' : 'px-5 pt-5'"
  >
    <v-row @click="openUrlInNewTab('https://bit.ly/3Wj9gDf')" class="clickable" v-if="!smAndUp">
      <v-col>
        <v-img :src="require('@/assets/mobile_banner.png')"></v-img>
      </v-col>
    </v-row>
    <v-row class="text-h5 align-end" v-if="smAndUp">
      <v-col class="align-self-end">
        <v-row>
          <v-col>
            숏부스트 AI가 영상을 찾아왔어요
          </v-col>
        </v-row>
        <v-row
            :dense="!smAndUp"
            class="mt-5"
        >
          <v-col cols="8">
            <v-text-field
                variant="outlined"
                density="compact"
                v-model="searchTerm"
                prepend-inner-icon="mdi-magnify"
                clearable
                hide-details
                single-line
                v-on:keyup.enter="search"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-row
                :dense="!smAndUp"
            >
              <v-col cols="6">
                <v-btn
                    class="text-none"
                    color="primary"
                    block
                    @click="search"
                >
                  검색
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-menu
                    v-model="pcSearchOptionMenu"
                    offset="10"
                    scroll-strategy="none"
                    :close-on-content-click="false"
                    location="bottom"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                        variant="outlined"
                        class="text-text-secondary"
                        block
                        v-bind="props"
                    >
                      검색 옵션
                    </v-btn>
                  </template>
                  <v-card min-width="700">
                    <v-card-text class="pa-10">
                      <v-row align="end" justify="end">
                        <v-btn
                            icon
                            variant="flat"
                        >
                          <v-icon
                              color="text-secondary"
                              @click="pcSearchOptionMenu = false"
                          >mdi-close
                          </v-icon>
                        </v-btn>
                      </v-row>
                      <v-row dense>
                        <v-col cols="10">
                          <v-row>
                            <v-col cols="11">
                              <v-select
                                  id="category"
                                  label="카테고리"
                                  hide-details="true"
                                  :items="categoryList"
                                  v-model="searchCommand.selectedCategory"
                                  item-title="description"
                                  item-value="name"
                                  variant="outlined"
                                  density="compact"
                                  menu-icon="mdi-chevron-down"
                              ></v-select>
                            </v-col>
                          </v-row>
                          </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="10">
                          <v-row id="upload" align="center">
                            <v-col cols="5">
                              <v-text-field
                                  label="업로드 시작일"
                                  block
                                  type="date"
                                  variant="outlined"
                                  hide-details="true"
                                  density="compact"
                                  v-model="searchCommand.startDate"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="1">
                              <v-icon>
                                mdi-tilde
                              </v-icon>
                            </v-col>
                            <v-col cols="5">
                              <v-text-field
                                  block
                                  label="업로드 종료일"
                                  type="date"
                                  variant="outlined"
                                  hide-details="true"
                                  density="compact"
                                  v-model="searchCommand.endDate"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="10">
                          <v-row>
                            <v-col cols="5">
                              <v-switch
                                  v-model="searchCommand.onlyNarrated"
                                  :label="`나레이션 있는 영상만`"
                                  color="primary"
                                  hide-details
                                  inset
                              ></v-switch>
                            </v-col>
                            <v-col cols="5" offset="1">
                              <v-switch
                                  v-model="searchCommand.includeForeign"
                                  :label="`해외 영상 포함하기`"
                                  color="primary"
                                  hide-details
                                  inset
                              ></v-switch>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-btn
                              class="text-none"
                              color="primary"
                              @click="viewWithSearchOption"
                          >
                            조회하기
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="5">
        <v-row @click="openUrlInNewTab('https://bit.ly/3Wj9gDf')" class="clickable">
          <v-col>
            <v-img :src="require('@/assets/pc_banner.png')"></v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="text-h5" v-else>
      <v-col>
        숏부스트 AI가 <br> 영상을 찾아왔어요
      </v-col>
    </v-row>
    <v-row
        :dense="!smAndUp"
        class="my-5"
        v-if="!smAndUp"
    >
      <v-col cols="7" sm="4">
        <v-text-field
            variant="outlined"
            density="compact"
            v-model="searchTerm"
            prepend-inner-icon="mdi-magnify"
            clearable
            hide-details
            single-line
            v-on:keyup.enter="search"
        >
        </v-text-field>
      </v-col>
      <v-col cols="5" sm="3">
        <v-row
            :dense="!smAndUp"
        >
          <v-col cols="6">
            <v-btn
                class="text-none"
                color="primary"
                block
                @click="search"
            >
              검색
            </v-btn>
          </v-col>
          <v-col cols="6">
            <!--            모바일 검색 옵션 창 -->
            <v-btn
                @click="mobileSearchDialogOn = true"
                variant="outlined"
                class="text-text-secondary"
                block
            >
              검색 옵션
            </v-btn>
            <v-dialog
                v-model="mobileSearchDialogOn"
                width="auto"
                transition="dialog-right-transition"
                fullscreen
            >
              <v-card
                  style="width: 85vw;"
              >
                <v-container>
                  <v-row justify="end">
                    <v-btn
                        variant="flat"
                        icon
                        @click="mobileSearchDialogOn = false"
                    >
                      <v-icon>
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </v-row>
                </v-container>
                <v-card-text>
                  <v-row class="pt-3">
                    <v-col class="pb-0">
                      카테고리
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col cols="12">
                      <v-select
                          id="category"
                          label="카테고리"
                          hide-details
                          :items="categoryList"
                          v-model="searchCommand.selectedCategory"
                          item-title="description"
                          item-value="name"
                          variant="outlined"
                          density="compact"
                          menu-icon="mdi-chevron-down"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="pt-3">
                    <v-divider/>
                    <v-col class="pb-0">
                      업로드 날짜
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          label="시작일"
                          block
                          type="date"
                          variant="outlined"
                          hide-details="true"
                          density="compact"
                          v-model="searchCommand.startDate"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          label="종료일"
                          block
                          type="date"
                          variant="outlined"
                          hide-details="true"
                          density="compact"
                          v-model="searchCommand.endDate"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pt-3" dense>
                    <v-divider/>
                    <v-col cols="12">
                      <v-switch
                          v-model="searchCommand.onlyNarrated"
                          :label="`나레이션 있는 영상만`"
                          color="primary"
                          hide-details
                          inset
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12">
                      <v-switch
                          v-model="searchCommand.includeForeign"
                          :label="`해외 영상 포함하기`"
                          color="primary"
                          hide-details
                          inset
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn
                          class="text-none"
                          color="primary"
                          @click="viewWithSearchOption"
                          block
                      >
                        조회하기
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider class="my-5"/>
  </v-container>
  <v-container
      :class="smAndUp ? 'pt-10' : 'pt-3'"
      v-if="this.zeroResult"
      fluid
  >
    <v-row justify="center" align="center">
      <v-card
          variant="flat"
      >
        <v-row justify="center" align="center">
          <v-col cols="8">
            <v-img
                :src="require('@/assets/empty.png')"
                width="60vh"
            ></v-img>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-card-title>
            대신 AI로 주제를 추천 해드릴게요!
          </v-card-title>
        </v-row>
        <v-row justify="center" align="center" class="pb-10 pt-3">
          <v-btn size="large" color="primary" @click="toTopicSuggestion">
            추천 받으러 가기
          </v-btn>
        </v-row>
      </v-card>
    </v-row>
  </v-container>
<!--  영상 컨텐츠 -->
  <v-container
      v-else
      fluid
      :class="smAndUp ? 'px-10 pt-3' : 'px-5 pt-5'"
  >
    <v-row
        v-if="!smAndUp"
    >
      <v-col
          cols="12"
          v-for="content in contentList"
          :key="content"
      >
        <MobileContentCard
            :upload-date='content.uploadDate'
            :thumbnail-url="content.thumbnailUrl"
            :play-count="content.playCount"
            :short-code="content.shortCode"
            :creator-profile-url="content.creatorProfileImageUrl"
            :creator-name="content.creatorName"
            :follower-count="content.followerCount"
            :like-count="content.likeCount"
            :comment-count="content.commentCount"
            @open-creator-detail-dialog="openMobileCreatorDetailDialog(content.creatorId)"
            @open-content-detail-dialog="openMobileContentDetailDialog(content.id)"
        >
        </MobileContentCard>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
          cols="2"
          v-for="content in contentList"
          :key="content"
      >
        <PCContentCard
            :upload-date='content.uploadDate'
            :thumbnail-url="content.thumbnailUrl"
            :play-count="content.playCount"
            :short-code="content.shortCode"
            :creator-profile-url="content.creatorProfileImageUrl"
            :creator-name="content.creatorName"
            :like-count="content.likeCount"
            :comment-count="content.commentCount"
            @open-creator-detail-dialog="openPCCreatorDetailDialog(content.creatorId)"
            @open-content-detail-dialog="openPCContentDetailDialog(content.id)"
        />
      </v-col>
    </v-row>
  </v-container>
  <v-row
      v-if="contentLoading"
      align="center"
      justify="center"
      class="py-3"
  >
    <v-progress-circular
        indeterminate
        :size="64"
        :width="8"
    ></v-progress-circular>
  </v-row>
  <v-container>
    <div v-if="this.loadMoreEnabled" ref="loadMoreRef"></div> <!-- 관찰 대상 요소 -->
  </v-container>

  <v-dialog
      v-model="showMobileCreatorDetailDialog"
      scrollable
      fullscreen
  >
    <MobileCreatorDetailDialog
        :creator-info="this.creatorDetailInfo"
        @close-dialog="this.showMobileCreatorDetailDialog = false"
    />
  </v-dialog>
  <v-dialog
      v-model="showMobileContentDetailDialog"
      scrollable
      fullscreen
  >
    <MobileContentDetailDialog
        :content-id = "this.openTargetContentId"
        @close-content-detail-dialog="this.showMobileContentDetailDialog = false"
    />
  </v-dialog>

  <v-dialog
      v-model="showPcCreatorDetailDialog"
      scrollable
  >
    <PCCreatorDetailDialog
        :creator-info="this.creatorDetailInfo"
        @close-dialog="this.showPcCreatorDetailDialog = false"
    />
  </v-dialog>
  <v-dialog
      v-model="showPcContentDetailDialog"
      width="70vw"
      height="90vh"
      scrollable
  >
    <PCContentDetailDialog
        :content-id = "this.openTargetContentId"
        @close-content-detail-dialog="this.showPcContentDetailDialog = false"
    />
  </v-dialog>
  <v-dialog
      v-model="showCreatorDetailUnavailableDialog"
      :width="smAndUp ? '40vw' : '100vw'"
  >
    <CreatorDetailUnavailableNoticeDialogCard
        @close-creator-detail-unavailable-notice-dialog="this.showCreatorDetailUnavailableDialog = false"
    />
  </v-dialog>
</template>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>