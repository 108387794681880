<script>
import {useDisplay} from "vuetify";

export default {
  name: "EnrollDonePage",
  setup() {
    const {smAndDown} = useDisplay()
    return {smAndDown}
  },
}
</script>

<template>
  <v-container
      fluid
      class="px-5 pt-5 justify-center h-100"
  >
    <v-row justify="center" align="center" class="h-100">
      <v-col align-self="center" :cols="smAndDown ? 12 : 5">
        <v-row align="center" justify="center">
          <v-sheet rounded="3">
            <v-card
                rounded="2"
                variant="flat"
            >
              <v-card-title class="font-weight-bold text-h6 text-center">
                <span class="text-blue-accent-2">
                  연동이 완료됐어요!
                </span>
              </v-card-title>
              <v-card-subtitle class="text-center">
                내일부터 내 계정 분석을 이용할 수 있습니다.
              </v-card-subtitle>
            </v-card>
            <v-container align="center">
              <v-img width="300" :src="require('@/assets/logo2.png')"></v-img>
            </v-container>
            <v-container
                class="pa-10"
            >
              <v-row
                  justify="center"
                  class="pb-4"
              >
                <v-btn color="primary" size="large" prepend-icon="mdi-chart-bar-stacked" to="/"
                >
                  숏부스트 다른 서비스 이용하기
                </v-btn>
              </v-row>
            </v-container>
          </v-sheet>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>

</style>