import {createRouter, createWebHistory} from "vue-router";
import store from "@/store";
import NewMainPage from "@/components/NewMainPage.vue";
import NewContentSearchPage from "@/components/hotshortform/NewContentSearchPage.vue";
import TopicSuggestionAIPage from "@/components/ai/TopicSuggestionAIPage.vue";
import HookingmentAIPage from "@/components/ai/HookingmentAIPage.vue";
import ScriptFeedbackAIPage from "@/components/ai/ScriptFeedbackAIPage.vue";
import PrivacyPolicyPage from "@/components/common/PrivacyPolicyPage.vue";
import ServiceTermsPage from "@/components/common/ServiceTermsPage.vue";
import GoogleLogin from "@/components/member/GoogleLogin.vue";
import MetaLoginCallback from "@/components/member/MetaLoginCallback.vue";
import MembershipSubscribePage from "@/components/membership/subscribe/MembershipSubscribePage.vue";
import MembershipProcessPage from "@/components/membership/subscribe/MembershipProcessPage.vue";
import NewMyChannelPage from "@/components/shortform/newMyPage/myPage/NewMyChannelPage.vue";
import EnrollStartPage from "@/components/shortform/newMyPage/manageAccount/EnrollStartPage.vue";
import SelectAccountPage from "@/components/shortform/newMyPage/manageAccount/SelectAccountPage.vue";
import EnrollDonePage from "@/components/shortform/newMyPage/manageAccount/EnrollDonePage.vue";
import AuthTokenCallBack from "@/components/shortform/newMyPage/manageAccount/AuthTokenCallBack.vue";

const routes = [
    {
        path: "/",
        component: NewMainPage,
    },
    {
        name: 'NewContentSearchPage',
        path: "/creator/new-content-search",
        component: NewContentSearchPage,
        meta: {requiresAuth: true},
        beforeEnter: (to, from, next) => {
            const searchTerm = to.query.searchTerm;
            const category = to.query.category;
            if (!category && !searchTerm) {
                next('/?type=content-search');
            } else {
                next();
            }
        }
    },
    {
        name: 'NewMyChannelPage',
        path: "/my-channel",
        component: NewMyChannelPage,
        meta: {requiresAuth: true},
    },
    // 계정 연동 관련
    {
        name: 'EnrollStartPage',
        path: "/enroll-my-channel",
        component: EnrollStartPage,
        meta: {requiresAuth: true},
    },
    {
        name: 'AuthTokenCallBack',
        path: "/enroll-my-channel/callback",
        component: AuthTokenCallBack,
        meta: {requiresAuth: true},
    },
    {
        name: 'SelectAccountPage',
        path: "/enroll-my-channel/select-account",
        component: SelectAccountPage,
        meta: {requiresAuth: true},
    },
    {
        name: "EnrollDonePage",
        path: "/enroll-my-channel/done",
        component: EnrollDonePage,
        meta: {requiresAuth: true},
    },


    {
        name: 'MembershipSubscribePage',
        path: "/membership/subscribe",
        component: MembershipSubscribePage,
        meta: {requiresAuth: true},
    },
    {
        name: 'MembershipProcessPage',
        path: "/membership/process",
        component: MembershipProcessPage,
        meta: {requiresAuth: true},
    },
    {
        name: 'TopicSuggestionAIPage',
        path: "/creator/topic-suggestion-ai",
        meta: {requiresAuth: true},
        component: TopicSuggestionAIPage,
        beforeEnter: (to, from, next) => {
            const uuid = to.query.uuid;
            if (!uuid) {
                next('/?type=topic-suggestion-ai');
            } else {
                next();
            }
        }
    },
    {
        name: 'HookingmentAIPage',
        path: "/creator/hookingment-ai",
        meta: {requiresAuth: true},
        component: HookingmentAIPage,
        beforeEnter: (to, from, next) => {
            const uuid = to.query.uuid;
            if (!uuid) {
                next('/?type=hookingment-ai');
            } else {
                next();
            }
        }
    },
    {
        name: 'ScriptFeedbackAIPage',
        meta: {requiresAuth: true},
        path: "/creator/script-feedback-ai",
        component: ScriptFeedbackAIPage,
        beforeEnter: (to, from, next) => {
            const uuid = to.query.uuid;
            if (!uuid) {
                next('/?type=script-feedback-ai');
            } else {
                next();
            }
        }
    },
    {
        path: "/creator/privacy",
        component: PrivacyPolicyPage,
    },
    {
        path: "/creator/terms",
        component: ServiceTermsPage,
    },
    {
        path: "/creator/login/google",
        component: GoogleLogin,
    },
    {
        path: "/creator/login/meta",
        component: MetaLoginCallback,
    },
    {
        name: 'NotFound',
        path: "/:pathMatch(.*)*",
        beforeEnter: (to, from, next) => {
            alert("페이지를 찾을 수 없습니다.");
            next("/");
        }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !store.state.isLogin) {
        if (!store.state.isLogin) {
            alert("로그인이 필요합니다.")
            store.commit('openLoginDialog');
            next("/");
        } else {
            next();
        }
    } else {
        next();
    }
})

export default router;
