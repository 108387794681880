<script>
import createApiService from "@/apiService";
import {convertDateFormat} from "@/utils";
import { Bar, Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)


export default {
  name: "PCContentDetailDialog",
  props: ['contentId'],
  components: { Bar, Line },
  data() {
    return {
      recentStats: [],
      playCountChartData: null,
      playCountChartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          x: {
            type: 'category',
          },
          y: {
            type: 'linear',
            position: 'left',
          },
          y1: {
            type: 'linear',
            position: 'right',
            grid: {
              drawOnChartArea: false,
            },
          },
        },
      },
      saveAndShareRatioChartData: null,
      saveAndShareRatioChartOptions: {
        responsive: true,
        indexAxis: 'y', // This makes the bar chart horizontal
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                if (context.parsed.x !== null) {
                  label += (context.parsed.x * 100).toFixed(2) + '%';
                }
                return label;
              }
            }
          }
        },
      }
    }
  },
  methods: {
    convertDateFormat,
    loadRecentDailyStatsOfContent(contentId) {
      this.api.get(`/api/my-content/${contentId}`)
          .then((response) => {
            switch (response.data.code) {
              case 'SUCCESS': {
                this.recentStats = response.data.data.dailyContentStatsList
                this.prepareChartData(
                    response.data.data.averageSaveAndShareRatio,
                    response.data.data.saveAndShareRatio
                )
                break;
              }
              case 'CREATOR_NOT_LINKED': {
                alert("연동된 계정이 없습니다.")
                break;
              }
              case 'BEFORE_FIRST_COLLECTION': {
                alert("채널 정보를 수집 중입니다. 잠시 후 다시 시도해주세요.")
                break;
              }
              default: {
                alert("문제가 발생했습니다. 지속적으로 발생 시 서비스 센터에 문의해주세요.")
              }
            }
          })
          .catch(() => {
            alert("문제가 발생했습니다. 지속적으로 발생 시 서비스 센터에 문의해주세요.")
          })
    },
    prepareChartData(average, content) {
      const dateList = this.recentStats.map(item => item.date).reverse()
      const playCountList = this.recentStats.map(item => item.playCount).reverse()
      const playCountIncreaseList = this.recentStats.map(item => item.playCountIncrease).reverse()

      this.playCountChartData = {
        labels: dateList,
        datasets: [
          {
            type: 'line',
            label: '총 조회 수',
            backgroundColor: '#FFFFFF',
            borderColor: '#8950FF',
            borderWidth: 1,
            fill: false,
            data: playCountList,
            yAxisID: 'y',
          },
          {
            type: 'bar',
            label: '조회 수 증가',
            backgroundColor: '#8950FF',
            data: playCountIncreaseList,
            barPercentage: 0.5,
            yAxisID: 'y1',
          }
        ]
      }
      this.saveAndShareRatioChartData = {
        labels: ['내 계정 평균', '해당 영상'],
        datasets: [
          {
            label: '조회수 대비 공유&저장 비율',
            data: [average, content], // Replace with your actual data
            backgroundColor: ['#8950FF', '#8950FF'],
            barPercentage: 0.5,
          }
        ]
      }
    }
  },
  created() {
    this.api = createApiService(this.$store, this.$router);
    this.loadRecentDailyStatsOfContent(this.contentId)
  },
}
</script>

<template>
  <v-card
      title="내 릴스 성과 상세"
      subtitle="내 릴스의 성과를 상세하게 확인해보세요!"
  >
    <template v-slot:append>
      <v-btn
          icon="mdi-close"
          @click="$emit('closeDialog')"
          variant="text"
      >
      </v-btn>
    </template>
    <v-card-text>
      <v-container v-if="this.playCountChartData !== null">
        <Line :options="playCountChartOptions" :data="playCountChartData"/>
      </v-container>
      <v-container v-if="this.saveAndShareRatioChartData !== null">
        <Bar :options="saveAndShareRatioChartOptions" :data="saveAndShareRatioChartData" />
      </v-container>
      <v-container>
          <v-table density="compact">
            <thead class="bg-grey-lighten-3">
            <tr>
              <th class="text-center">날짜</th>
              <th class="text-center">조회수</th>
              <th class="text-center">좋아요</th>
              <th class="text-center">댓글</th>
              <th class="text-center">공유</th>
              <th class="text-center">저장</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="stat in recentStats" :key="stat">
              <td class="text-center"> {{ convertDateFormat(stat.date) }} </td>
              <td class="text-center">
                {{ stat.playCount }}
                <v-badge
                    v-if="stat.playCountIncrease != null"
                    color="green"
                    :content="stat.playCountIncrease"
                    inline
                    style="vertical-align: middle"
                />
              </td>
              <td class="text-center">
                {{ stat.likeCount }}
                <v-badge
                    v-if="stat.likeCountIncrease != null"
                    color="green"
                    :content="stat.likeCountIncrease"
                    inline
                    style="vertical-align: middle"
                />
              </td>
              <td class="text-center">
                {{ stat.commentCount }}
                <v-badge
                    v-if="stat.commentCountIncrease != null"
                    color="green"
                    :content="stat.commentCountIncrease"
                    inline
                    style="vertical-align: middle"
                />
              </td>
              <td class="text-center">
                {{ stat.shareCount }}
                <v-badge
                    v-if="stat.shareCountIncrease != null"
                    color="green"
                    :content="stat.shareCountIncrease"
                    inline
                    style="vertical-align: middle"
                />
              </td>
              <td class="text-center">
                {{ stat.saveCount }}
                <v-badge
                    v-if="stat.saveCountIncrease != null"
                    color="green"
                    :content="stat.saveCountIncrease"
                    inline
                    style="vertical-align: middle"
                />
              </td>
            </tr>
            </tbody>
          </v-table>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<style scoped>

</style>