<script>
export default {
  name: "AuthTokenCallBack",
  created() {
    window.onload = function() {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const hash = url.hash;
      const queryString = hash.substring(1);
      const params = new URLSearchParams(queryString);
      const accessToken = params.get('access_token');
      if (accessToken) {
        window.opener.postMessage({ code: accessToken, type: "metaAccountEnrollAuthCode" }, "*");
        window.close();
      }
    };
  },
}
</script>

<template>
  <v-container fluid=true>
    <v-sheet>
      <v-container>
      </v-container>
    </v-sheet>
  </v-container>
</template>

<style scoped>

</style>