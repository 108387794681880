<script>

import {formatNumber} from "@/utils";

export default {
  name: "PCTopContentCard",
  methods: {formatNumber},
  props: ['yesterdayHotContentList'],
}
</script>

<template>
  <v-card variant="flat">
    <v-row class="align-center">
      <v-col cols="auto">
        <v-card-title class="text-h5">
          내 릴스 인기 순위
          <v-tooltip text="어제 내 릴스의 조회수 증가를 기준으로 측정됩니다.">
            <template v-slot:activator="{ props }">
              <v-btn
                  icon="mdi-exclamation"
                  density="comfortable"
                  v-bind="props"
                  size="x-small"
                  class="mb-1"
                  variant="outlined">
              </v-btn>
            </template>
          </v-tooltip>
        </v-card-title>
      </v-col>
    </v-row>
    <v-card-text>
      <v-row>
        <v-col
            v-for="(content, idx) in yesterdayHotContentList"
            :key="content.id"
            cols="4"
            @click="$emit('open-content-detail-dialog', content.id)"
        >
          <v-img
              id="content-image"
              :src="content.thumbnailUrl"
              style="border-radius: 2%; aspect-ratio: 9 / 12;"
              class="clickable"
              cover
          >
            <v-container
                class="overlay-top"
            >
              <v-row no-gutters justify="end">
                <v-col cols="auto">
                  {{ content.uploadedDate }}
                </v-col>
              </v-row>
            </v-container>
            <v-container
                class="overlay-bottom"
            >
              <v-row no-gutters align="center">
                <v-col cols="auto">
                  <v-icon>
                    mdi-play
                  </v-icon>
                </v-col>
                <v-col cols="auto" class="align-center text-center">
                  {{ formatNumber(content.playCount) }}
                </v-col>
              </v-row>
            </v-container>
          </v-img>
          <div class="text-h5 mt-2 align-center justify-center d-flex">
            <span v-if="idx === 0">
              🥇
            </span>
            <span v-else-if="idx === 1">
              🥈
            </span>
            <span v-else>
              🥉
            </span>
            <span style="font-size: 18px" class="pl-2">
              조회수 +{{formatNumber(content.playCountIncreaseCount)}}
            </span>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.overlay-bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  font-weight: bold;
  transform: translateX(-50%);
  color: white;
  padding: 8px;
  border-radius: 5px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent); /* 그라데이션 효과 */
}

.overlay-top {
  position: absolute;
  top: 0;
  left: 50%;
  font-weight: bold;
  transform: translateX(-50%);
  color: white;
  padding: 8px;
  border-radius: 5px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), transparent); /* 그라데이션 효과 */
}

.text-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.clickable {
  cursor: pointer;
}

.image-container {
  position: relative;
  overflow: hidden;
  aspect-ratio: 9 / 16;
  border-radius: 2%;
}

</style>