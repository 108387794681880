<script>
import {formatNumber, openUrlInNewTab} from "@/utils";
import createApiService from "@/apiService";

export default {
  name: "PCContentDetailDialog",
  methods: {
    formatNumber,
    openUrlInNewTab,
    loadContentDetail() {
      this.api.get(`/api/hot-short-form/content/${this.contentId}`)
          .then((response) => {
            this.contentDetail = response.data.data;
          })
          .catch(() => {
            alert('문제가 발생했습니다. 다시 시도해주세요.');
            this.$emit('close-content-detail-dialog');
          });
    },
    calculateRows() {
      const vh = window.innerHeight * 0.67 // 70vh를 픽셀로 변환
      const rowHeight = 26 // 한 row의 픽셀 높이
      this.calculatedRows = Math.floor(vh / rowHeight)
    },
  },
  props: ['contentId'],
  data: () => ({
    calculatedRows: 20, // 기본값
    showOriginalScript: true,
    showOriginalCaption: true,
    contentDetail: null,
  }),
  computed:  {
    displayNarration() {
      if (this.contentDetail.language === 'KOREAN') {
        if (this.contentDetail.script === null) {
          return '나레이션이 없거나, 숏부스트 AI가 수집 전이에요.'
        }
        else return this.contentDetail.script
      } else {
        if (this.contentDetail.translatedScript === null) {
          return '나레이션이 없거나, 숏부스트 AI가 수집 전이에요.'
        }
        return this.contentDetail.translatedScript
      }
    },
    displayCaption() {
      if (this.contentDetail.language === 'KOREAN') {
        if (this.contentDetail.caption === null) {
          return '캡션이 없어요!'
        }
        else return this.contentDetail.caption
      } else {
        if (this.contentDetail.translatedCaption === null) {
          return '캡션이 없어요!'
        }
        return this.contentDetail.translatedCaption
      }
    },
    displayTopic() {
      if (this.contentDetail.topic === null) {
        return 'AI가 아직 주제를 파악하지 못했어요.'
      } else {
        return `주제 : ${this.contentDetail.topic}`
      }
    }
  },
  mounted() {
    this.calculateRows()
    window.addEventListener('resize', this.calculateRows)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calculateRows)
  },
  created() {
    this.api = createApiService(this.$store, this.$router);
    this.loadContentDetail()
  }
}
</script>

<template>
  <v-card variant="flat" v-if="this.contentDetail !== null">
    <v-card-title>
      <v-row align="center" class="pt-10 px-10">
        <v-col cols="11">
          {{ displayTopic }}
        </v-col>
        <v-col>
          <v-btn
              icon="mdi-close"
              @click="$emit('close-content-detail-dialog')"
              variant="text"
          >
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row
          class="mx-5"
          align="center"
      >
        <v-col cols="4">
          <v-img
              id="content-image"
              :src="contentDetail.thumbnailUrl"
              style="border-radius: 2%; aspect-ratio: 9 / 16; height: 70vh"
              class="clickable"
              cover
              @click="openUrlInNewTab(contentDetail.contentUrl)"
          >
            <v-container
                class="overlay-top"
            >
              <v-row no-gutters justify="end">
                <v-col cols="auto">
                  {{ contentDetail.uploadedDate }}
                </v-col>
              </v-row>
            </v-container>
            <v-container
                class="overlay-bottom"
            >
              <v-row no-gutters>
                <v-col cols="auto">
                  <v-icon>
                    mdi-play
                  </v-icon>
                </v-col>
                <v-col cols="auto">
                  {{ formatNumber(contentDetail.playCount) }}
                </v-col>
              </v-row>
            </v-container>
          </v-img>
        </v-col>
        <v-col cols="4">
          <v-textarea
              label="캡션"
              readonly
              :rows="this.calculatedRows"
              block
              variant="solo"
              hide-details
              no-resize
              :model-value="this.displayCaption"
          >
          </v-textarea>
        </v-col>
        <v-col cols="4">
          <v-textarea
              label="나레이션"
              readonly
              :rows="this.calculatedRows"
              block
              variant="solo"
              hide-details
              no-resize
              :model-value="this.displayNarration"
          >
          </v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.clickable {
  cursor: pointer;
}

.overlay-bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  font-weight: bold;
  transform: translateX(-50%);
  color: white;
  padding: 8px;
  border-radius: 5px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent); /* 그라데이션 효과 */
}

.overlay-top {
  position: absolute;
  top: 0;
  left: 50%;
  font-weight: bold;
  transform: translateX(-50%);
  color: white;
  padding: 8px;
  border-radius: 5px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), transparent); /* 그라데이션 효과 */
}
</style>