<script>
export default {
  name: "AppFooter"
}
</script>

<template>
  <v-row
      justify="center"
      align="center"
  >
    <v-col cols="auto" align-self="center" class="font-weight-bold">
      @ {{ new Date().getFullYear() }} 로아트 Lab
    </v-col>
    <v-col cols="auto" align-self="center">
      <v-btn
          class="mx-2 text-grey-darken-1"
          variant="text"
          to="/creator/terms"
      >
        이용약관
      </v-btn>
    </v-col>
    <v-col cols="auto" align-self="center">
      <v-btn
          class="mx-2 text-grey-darken-1"
          variant="text"
          to="/creator/privacy"
      >
        개인정보처리방침
      </v-btn>
    </v-col>
    <v-col cols="auto" align-self="center">
      <v-btn
          class="mx-2 text-grey-darken-1"
          variant="text"
          @click="$emit('open-company-info-dialog')"
      >
        사업자 정보
      </v-btn>
    </v-col>
  </v-row>
</template>

<style scoped>

</style>