<script>
import {useDisplay} from "vuetify";
import PCProfile from "@/components/shortform/newMyPage/myPage/pc/PCProfile.vue";
import MobileProfile from "@/components/shortform/newMyPage/myPage/mobile/MobileProfile.vue";
import PCContentDetailDialog from "@/components/shortform/newMyPage/myPage/pc/PCContentDetailDialog.vue";
import PCDailyStatsDetailDialog from "@/components/shortform/newMyPage/myPage/pc/PCDailyStatsDetailDialog.vue";
import MobileContentCard from "@/components/shortform/newMyPage/myPage/mobile/MobileContentCard.vue";
import PCContentCard from "@/components/shortform/newMyPage/myPage/pc/PCContentCard.vue";
import MobileContentDetailDialog from "@/components/shortform/newMyPage/myPage/mobile/MobileContentDetailDialog.vue";
import MobileDailyStatsDetailDialog from "@/components/shortform/newMyPage/myPage/mobile/MobileDailyStatsDetailDialog.vue";
import createApiService from "@/apiService";
import FollowersTrendChart from "@/components/shortform/newMyPage/myPage/FollowerTrendChart.vue";
import PCYesterdayCard from "@/components/shortform/newMyPage/myPage/pc/PCYesterdayCard.vue";
import PCTopContentCard from "@/components/shortform/newMyPage/myPage/pc/PCTopContentCard.vue";
import MobileTopContentCard from "@/components/shortform/newMyPage/myPage/mobile/MobileTopContentCard.vue";

export default {
  name: "NewMyChannelPage",
  components: {
    MobileTopContentCard,
    PCTopContentCard,
    PCYesterdayCard,
    FollowersTrendChart,
    MobileDailyStatsDetailDialog,
    MobileContentDetailDialog,
    MobileContentCard, MobileProfile, PCProfile, PCContentDetailDialog, PCDailyStatsDetailDialog, PCContentCard
  },
  methods: {
    openContentDetailDialog(contentId) {
      this.contentDetailDialogContentId = contentId
      this.pcContentDetailDialog = true
    },
    openMobileContentDetailDialog(contentId) {
      this.contentDetailDialogContentId = contentId
      this.mobileContentDetailDialog = true
    },
    getMyChannelInfo() {
      return this.api.get('/api/my-channel/summary')
          .then((response) => {
            switch (response.data.code) {
              case 'SUCCESS': {
                this.channelSummaryInfo = response.data.data
                break;
              }
              case 'CREATOR_NOT_LINKED': {
                alert("연동된 계정이 없습니다. 메인페이지로 이동할게요.")
                this.$router.push('/')
                break;
              }
              case 'BEFORE_FIRST_COLLECTION': {
                alert("채널 정보를 수집 중입니다. 매일 밤 12시에 데이터를 수집 중이에요. \n내일 시도해주세요. 메인페이지로 이동할게요.")
                this.$router.push('/')
                break;
              }
              default: {
                alert("문제가 발생했습니다. 지속적으로 발생 시 서비스 센터에 문의해주세요.")
              }
            }
          })
          .catch(() => {
            alert("문제가 발생했습니다. 지속적으로 발생 시 서비스 센터에 문의해주세요.")
      })
    },
    listContent() {
      if (this.contentLoading) {
        return;
      }
      this.zeroResult = false;
      this.contentLoading = true;

      return this.api.get('/api/my-content', {
        params: {
          sortType: this.selectedContentSortOption,
          pageNo: this.currentPageNo
        }
      })
          .then((response) => {
            switch (response.data.code) {
              case 'SUCCESS': {
                if (response.data.data.length < 12) {
                  this.loadMoreEnabled = false
                }
                if (response.data.data.length === 0 && this.currentPageNo === 1) {
                  this.zeroResult = true;
                }

                response.data.data.forEach((content) => {
                  this.contentList.push(content)
                })
                break;
              }
            }
          })
          .catch(() => {
            alert("문제가 발생했습니다. 지속적으로 발생 시 서비스 센터에 문의해주세요.")
          })
          .finally(() => {
            this.contentLoading = false;
          })
    },
    loadNextPage() {
      if (this.contentLoading) return;
      this.currentPageNo += 1
      this.listContent()
    }
  },
  data() {
    return {
      pcDailyStatsDialog: false,
      pcContentDetailDialog: false,
      mobileDailyStatsDialog: false,
      mobileContentDetailDialog: false,
      contentDetailDialogContentId: null,

      loadMoreEnabled: true,
      contentLoading: false,
      zeroResult: false,

      sortOptions: [
        {text: '날짜', key: 'DATE'},
        {text: '조회수', key: 'PLAY_COUNT'},
        {text: '좋아요', key: 'LIKE_COUNT'},
        {text: '댓글', key: 'COMMENT_COUNT'},
        {text: '공유', key: 'SHARE_COUNT'},
        {text: '저장', key: 'SAVE_COUNT'}
      ],
      currentPageNo: 1,
      selectedContentSortOption: 'DATE',
      contentList: [],
      channelSummaryInfo: null,
    }
  },
  created() {
    this.api = createApiService(this.$store, this.$router);
    this.getMyChannelInfo()
    this.listContent()
  },
  mounted() {
    this.$nextTick(() => {
      this.observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && this.loadMoreEnabled) {
          this.loadNextPage();
        }
      });
      this.observer.observe(this.$refs.loadMoreRef);
    });
  },
  watch: {
    selectedContentSortOption() {
      this.contentList = []
      this.currentPageNo = 1
      this.loadMoreEnabled = true
      this.listContent()
      this.$nextTick(() => {
        if (this.loadMoreEnabled) {
          this.observer.observe(this.$refs.loadMoreRef);
        }
      });
    }
  },
  setup() {
    const {smAndDown, smAndUp} = useDisplay()
    return {smAndDown, smAndUp}
  },
}
</script>

<template>
<!--  모바일 -->
  <v-container
      fluid
      v-if="smAndDown"
      class="px-5 pt-5"
  >
    <div v-if="this.channelSummaryInfo !== null">
      <v-row>
        <MobileProfile
            @mobile-daily-stats-dialog-on="mobileDailyStatsDialog=true"
            :yesterday-stats="channelSummaryInfo.yesterdayStats"
            :my-channel-info="channelSummaryInfo.myChannelInfo"
        />
      </v-row>
      <v-row>
        <v-divider class="ma-3"/>
      </v-row>
      <v-row class="px-2">
        <v-col>
          <div class="text-h6">
            내 팔로워 추이
          </div>
        </v-col>
      </v-row>
      <v-row class="px-2">
        <FollowersTrendChart
            :follower-trend-list="channelSummaryInfo.followerTrendList"
        />
      </v-row>
      <v-row>
        <v-divider class="ma-3"/>
      </v-row>
      <v-row class="px-2">
        <MobileTopContentCard
            :yesterday-hot-content-list="channelSummaryInfo.yesterdayHotContentList"
            @open-content-detail-dialog="this.openMobileContentDetailDialog"
        />
      </v-row>
    </div>
    <v-row>
      <v-divider class="ma-3"/>
    </v-row>
    <v-row class="justify-space-between pb-1 px-2 align-center">
      <v-col>
        <div class="text-h6">
          영상 상세 보기
        </div>
      </v-col>
      <v-col cols="6">
        <v-select
            :items="sortOptions"
            item-title="text"
            item-value="key"
            v-model="selectedContentSortOption"
            density="compact"
            hide-details
            label="정렬 옵션"
            variant="outlined"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="this.contentList !== null" class="mt-0">
      <v-col
          cols="12"
          v-for="content in contentList"
          :key="content"
      >
        <MobileContentCard
            @click="this.openMobileContentDetailDialog(content.id)"
            :thumbnailUrl="content.thumbnailUrl"
            :uploadDate="content.date"
            :playCount="content.playCount"
            :likeCount="content.likeCount"
            :commentCount="content.commentCount"
            :shareCount="content.shareCount"
            :saveCount="content.saveCount"
        />
      </v-col>
    </v-row>
  </v-container>
<!--  PC -->
  <v-container
      v-else
      fluid="true"
      class="px-10 pt-10"
  >
    <div v-if="this.channelSummaryInfo !== null">
      <v-row>
        <v-col cols="5">
          <PCProfile :my-channel-info="channelSummaryInfo.myChannelInfo"/>
        </v-col>
        <v-col cols="7">
          <PCYesterdayCard
              :yesterday-stats="channelSummaryInfo.yesterdayStats"
              @pc-daily-stats-dialog-on="pcDailyStatsDialog=true"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <FollowersTrendChart
              :follower-trend-list="channelSummaryInfo.followerTrendList"
          />
        </v-col>
        <v-col cols="6">
          <v-row>
            <PCTopContentCard
                :yesterday-hot-content-list="channelSummaryInfo.yesterdayHotContentList"
                @open-content-detail-dialog="this.openContentDetailDialog"
            />
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-divider class="my-5"/>
    </v-row>
    <v-row class="justify-space-between" align="center">
      <v-col>
        <div class="text-h5">
          내 릴스 성과 상세히 보기 (영상 클릭)
        </div>
      </v-col>
      <v-col cols="2" align-self="center">
        <v-select
            :items="sortOptions"
            item-title="text"
            item-value="key"
            v-model="selectedContentSortOption"
            density="compact"
            label="정렬 옵션"
            variant="outlined"
            hide-details
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="this.contentList !== null">
      <v-col
          cols="2"
          v-for="content in contentList"
          :key="content"
      >
        <PCContentCard
            @click="this.openContentDetailDialog(content.id)"
            :thumbnailUrl="content.thumbnailUrl"
            :uploadDate="content.date"
            :playCount="content.playCount"
            :likeCount="content.likeCount"
            :commentCount="content.commentCount"
            :shareCount="content.shareCount"
            :saveCount="content.saveCount"
        ></PCContentCard>
      </v-col>
    </v-row>
  </v-container>
  <v-container>
    <div v-if="this.loadMoreEnabled" ref="loadMoreRef"></div> <!-- 관찰 대상 요소 -->
  </v-container>

  <v-dialog
      v-model="pcDailyStatsDialog"
      width="auto"
  >
    <PCDailyStatsDetailDialog
        @close-dialog="pcDailyStatsDialog=false"
    />
  </v-dialog>
  <v-dialog
      v-model="pcContentDetailDialog"
      width="auto"
      min-width="800px"
      max-width="800px"
  >
    <PCContentDetailDialog
        :content-id="this.contentDetailDialogContentId"
        @close-dialog="pcContentDetailDialog=false"
    />
  </v-dialog>

  <v-dialog
      v-model="mobileContentDetailDialog"
      width="auto"
  >
    <MobileContentDetailDialog
        :content-id="this.contentDetailDialogContentId"
        @close-dialog="mobileContentDetailDialog=false"
    />
  </v-dialog>
  <v-dialog
      v-model="mobileDailyStatsDialog"
  >
    <MobileDailyStatsDetailDialog
        @close-dialog="mobileDailyStatsDialog=false"
    />
  </v-dialog>

</template>

<style scoped>

</style>