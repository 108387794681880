<script>
export default {
  name: "MetaLoginCallback",
  data () {
    return {
      appUrl: process.env.VUE_APP_URL
    }
  },
  methods: {
    sendAuthData() {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const hash = url.hash;
      const queryString = hash.substring(1);
      const params = new URLSearchParams(queryString);
      if (params.has('access_token')) {
        window.opener.postMessage({ type: "metaAuthCode", code: params.get('access_token') }, this.appUrl);
      }
      window.close();
    }
  },
  mounted() {
    this.sendAuthData();
  }
}
</script>

<template>
  <v-container fluid=true>
    <v-sheet>
      <v-container>
      </v-container>
    </v-sheet>
  </v-container>
</template>

<style scoped>

</style>