<script>
import {formatNumber} from "@/utils";

export default {
  name: "MobileContentCard",
  props: ['uploadDate', 'thumbnailUrl', 'playCount', 'shortCode', 'creatorProfileUrl', 'creatorName', 'followerCount', 'likeCount', 'commentCount'],
  methods: {formatNumber},
  computed: {
    contentUrl() {
      return `https://www.instagram.com/p/${this.shortCode}/`
    }
  }
}
</script>

<template>
  <v-row
      align="center"
      no-gutters="true"
  >
    <v-col cols="7">
      <v-img
          id="content-image"
          :src="thumbnailUrl"
          style="border-radius: 2%; aspect-ratio: 9 / 16;"
          class="clickable"
          cover
          @click="$emit('open-content-detail-dialog')"
      >
        <v-container
            class="overlay-bottom"
        >
          <v-row no-gutters>
            <v-col cols="auto">
              <v-icon>
                mdi-play
              </v-icon>
            </v-col>
            <v-col cols="auto">
              {{ formatNumber(playCount) }}
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </v-col>
    <v-col cols="5">
      <v-card
          variant="flat"
      >
        <v-card-item class="px-2" @click="$emit('open-creator-detail-dialog')">
          <v-row dense="true" align="center">
            <v-col cols="auto">
              <v-avatar variant="flat">
                <v-img :src="creatorProfileUrl" width="45" height="45"></v-img>
              </v-avatar>
            </v-col>
            <v-col class="text-clamp" style="font-size: 16px">
              {{ creatorName }}
            </v-col>
          </v-row>
        </v-card-item>
        <v-card-item class="px-2 py-0">
          <label
              for="followCount"
              class="text-text-secondary"
              style="font-size: 14px"
          >팔로워
          </label>
          <v-text-field
              id="followCount"
              variant="outlined"
              density="compact"
              base-color="text-secondary"
              readonly="true"
              hide-details
          >
            {{ formatNumber(followerCount) }}
          </v-text-field>
        </v-card-item>
        <v-card-item class="px-2 py-0">
          <label
              for="followCount"
              class="text-text-secondary"
              style="font-size: 14px"
          >좋아요
          </label>
          <v-text-field
              id="followCount"
              variant="outlined"
              density="compact"
              base-color="text-secondary"
              readonly="true"
              hide-details
          >
            {{ formatNumber(likeCount) }}
          </v-text-field>
        </v-card-item>
        <v-card-item class="px-2 py-0">
          <label
              for="followCount"
              class="text-text-secondary"
              style="font-size: 14px"
          >댓글
          </label>
          <v-text-field
              id="followCount"
              variant="outlined"
              density="compact"
              base-color="text-secondary"
              readonly="true"
              hide-details
          >
            {{ formatNumber(commentCount) }}
          </v-text-field>
        </v-card-item>
        <v-card-item class="px-2">
          <label
              for="uploadDate"
              class="text-text-secondary"
              style="font-size: 14px"
          >업로드 날짜
          </label>
          <v-text-field
              id="uploadDate"
              variant="outlined"
              density="compact"
              base-color="text-secondary"
              readonly="true"
              hide-details
              class="text-body-1"
          >
            {{ uploadDate }}
          </v-text-field>
        </v-card-item>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped>
.text-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.overlay-bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  font-weight: bold;
  font-size: 18px;
  transform: translateX(-50%);
  color: white;
  padding: 8px;
  border-radius: 5px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent); /* 그라데이션 효과 */
}
</style>