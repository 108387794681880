<script>
import createApiService from "@/apiService";
import {openUrlInNewTab} from "@/utils";

export default {
  name: "MyAccountInfo",
  data() {
    return {
      myAccountInfo: null,
      membershipInfo: null,
    }
  },
  methods: {
    getAccountInfo() {
      this.api.get("/api/member/my-info")
          .then((response) => { this.myAccountInfo = response.data.data })
    },
    getMembershipInfo() {
      this.api.get("/api/membership/my")
          .then((response) => { this.membershipInfo = response.data.data })
    },
    toSubscribePage() {
      this.$emit('close-my-info-dialog')
      this.$router.push("/membership/subscribe")
    },
    unsubscribe() {
      if (confirm('멤버십 구독을 취소하시겠습니까?\n다음 달부터 무료 멤버십으로 전환됩니다.')) {
        this.$emit('close-my-info-dialog')
        this.$router.push('/membership/process?plan=' + 'Free' + '&type=downgrade')
      }
    },
    openCustomPage() {
      openUrlInNewTab("http://pf.kakao.com/_xfqBEG")
    },
  },
  created() {
    this.api = createApiService(this.$store, this.$router);
    this.getMembershipInfo()
    this.getAccountInfo()
  }
}
</script>

<template>
  <v-card
      variant="flat"
      rounded="lg"
      class="py-2"
  >
    <v-card-text>
      <v-container>
        <v-sheet>
          <v-row class="align-center">
            <v-col class="text-h5 font-weight-bold py-2">
              계정 정보
            </v-col>
            <v-col class="d-flex justify-end">
              <v-btn
                  icon="mdi-close"
                  @click="$emit('closeMyInfoDialog')"
                  variant="text"
              />
            </v-col>
          </v-row>
          <div v-if="myAccountInfo != null">
            가입 이메일 - {{ myAccountInfo.email }}
          </div>
        </v-sheet>
      </v-container>
      <v-divider></v-divider>
      <v-container v-if="membershipInfo != null">
        <div class="text-h5 font-weight-bold py-2">
          멤버십 정보
        </div>
        <v-sheet
            border="sm"
            class="pa-3"
        >
          <div class="text-h6 font-weight-bold">
            {{ membershipInfo.planName }} 멤버십
          </div>
          <div>
            {{ membershipInfo.planPrice.toLocaleString() }}원/달
          </div>
          <div class="pt-2 pb-4">
            <v-btn block size="large" variant="outlined" color="primary" @click="toSubscribePage">
              <div v-if="membershipInfo.planName !== 'Free'">멤버십 등급 변경</div>
              <div v-else>멤버십 가입하기</div>
            </v-btn>
          </div>
          <v-divider></v-divider>
          <div class="pt-4 text-h6 font-weight-bold">
            다음 결제일
          </div>
          <div>
            {{ membershipInfo.nextPaymentDate }}
          </div>
          <div class="pt-4 text-h6 font-weight-bold">
            다음 달 적용 멤버십
          </div>
          <div>
            {{ membershipInfo.nextPlanName }}
          </div>
          <div class="pt-2 text-h6 font-weight-bold">
            결제 정보
          </div>
          <div>
            {{ membershipInfo.paymentMethod }}
          </div>
          <button class="text-button link-button" @click="this.unsubscribe" v-if="membershipInfo.planName !== 'Free'">
            멤버십 구독 취소
          </button>
        </v-sheet>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.link-button {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  font-size: inherit;
}

.link-button:focus {
  outline: none;
}

</style>