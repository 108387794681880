<script>

import {formatNumber} from "@/utils";

export default {
  name: "PCContentCard",
  methods: {formatNumber},
  props: [
    "thumbnailUrl",
    "uploadDate",
    "playCount",
    "likeCount",
    "commentCount",
    "shareCount",
    "saveCount",
  ],
}
</script>

<template>
  <v-card
      variant="flat"
  >
    <v-img
        id="content-image"
        :src="thumbnailUrl"
        style="border-radius: 2%; aspect-ratio: 9 / 16;"
        class="clickable"
        cover
    >
      <v-container
          class="overlay-top"
      >
        <v-row no-gutters justify="end">
          <v-col cols="auto">
            {{ uploadDate }}
          </v-col>
        </v-row>
      </v-container>
      <v-container
          class="overlay-bottom"
      >
        <v-row no-gutters>
          <v-col cols="auto">
            <v-icon>
              mdi-play
            </v-icon>
          </v-col>
          <v-col cols="auto">
            {{ formatNumber(playCount) }}
          </v-col>
        </v-row>
      </v-container>
    </v-img>

    <v-card-title class="py-0 px-0">
      <v-row dense="true" class="my-0">
        <v-col cols="6" align-self="start">
          <v-btn
              block
              :ripple="false"
              prepend-icon="mdi-heart-outline"
              variant="flat"
              size="large"
              density="compact"
          >
            {{ formatNumber(likeCount) }}
          </v-btn>
        </v-col>
        <v-col cols="6" align-self="start">
          <v-btn
              block
              :ripple="false"
              prepend-icon="mdi-comment-outline"
              variant="flat"
              size="large"
              density="compact"
          >
            {{ formatNumber(commentCount) }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense="true" class="my-0">
        <v-col cols="6" align-self="start">
          <v-btn
              block
              :ripple="false"
              prepend-icon="mdi-share-outline"
              variant="flat"
              size="large"
              density="compact"
          >
            {{ formatNumber(shareCount) }}
          </v-btn>
        </v-col>
        <v-col cols="6" align-self="start">
          <v-btn
              block
              :ripple="false"
              prepend-icon="mdi-bookmark-outline"
              variant="flat"
              size="large"
              density="compact"
          >
            {{ formatNumber(saveCount) }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
  </v-card>
</template>

<style scoped>
.overlay-bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  font-weight: bold;
  transform: translateX(-50%);
  color: white;
  padding: 8px;
  border-radius: 5px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent); /* 그라데이션 효과 */
}

.overlay-top {
  position: absolute;
  top: 0;
  left: 50%;
  font-weight: bold;
  transform: translateX(-50%);
  color: white;
  padding: 8px;
  border-radius: 5px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), transparent); /* 그라데이션 효과 */
}

.text-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.clickable {
  cursor: pointer;
}
</style>