export function openUrlInNewTab(url) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        window.open(url, '_self');
    } else {
        window.open(url, '_blank');
    }
}

export function formatNumber(number) {
    if (number === -1) {
        return '비공개'
    }
    if (number < 10000) {
        return number.toString();
    } else if (number < 100000000) {
        const tenThousand = Math.floor(number / 1000) / 10; // 천의 자리에서 내림
        return tenThousand.toFixed(1) + '만';
    } else {
        const hundredMillion = Math.floor(number / 1000000) / 100; // 백만의 자리에서 내림
        return hundredMillion.toFixed(1) + '억';
    }
}

export  function formatToPercentage(number) {
    // 소수점 5째 자리에서 반올림
    const rounded = Math.round(number * 10000) / 10000;

    // 퍼센트로 변환 (100을 곱함)
    const percentage = rounded * 100;

    // 소수점 둘째 자리까지 표시하고 % 기호 추가
    return percentage.toFixed(2) + '%';
}


export function addLineBreaks(text) {
    return text.replace(/\n/g, '<br>');
}

export function truncateText(text) {
    if (text == null) {
        return ''
    }
    const breakIndex = text.indexOf('\n');
    if (breakIndex !== -1 && breakIndex <= 30) {
        return text.substring(0, breakIndex) + '...';
    }
    return text.length > 30 ? text.substring(0, 30) + '...' : text;
}

export function generateRandomString() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    const charactersLength = characters.length;

    for (let i = 0; i < 32; i++) {
        const randomIndex = Math.floor(Math.random() * charactersLength);
        result += characters.charAt(randomIndex);
    }

    return result;
}

export function convertDateFormat(inputDate) {
    let datePart = inputDate.match(/\d+/g),
        month = datePart[1],
        day = datePart[2];

    return month+'.'+day;
}
