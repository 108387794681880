<script>
const videoListInit = [
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240415/2.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240415/3.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240415/5.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240415/7.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240415/8.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240415/9.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240415/10.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240415/11.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240415/12.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240415/13.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240415/14.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240415/15.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240415/17.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240415/18.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240415/22.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/1.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/4.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/5.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/7.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/8.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/9.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/10.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/11.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/12.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/13.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/14.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/15.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/16.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/17.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/19.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/20.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/21.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/22.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/23.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/24.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/25.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/26.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/27.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/28.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/29.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/30.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/31.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/32.mp4",
  "https://adeu-images.s3.ap-northeast-2.amazonaws.com/loading-ad/240501/33.mp4"
]
export default {
  name: "AILoadingPage",
  data: () => ({
    loading: true,
    videoList: videoListInit,
    currentVideoIndex: Math.floor(Math.random() * videoListInit.length),
  }),
  methods: {
    changeVideo() {
      let newIndex;
      do {
        newIndex = Math.floor(Math.random() * this.videoList.length);
      } while (newIndex === this.currentVideoIndex);
      this.currentVideoIndex = newIndex;
      this.$refs.video.src = this.videoList[this.currentVideoIndex];
    },
  },
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 1500);
  }
}
</script>

<template>
  <v-container
      v-if="loading"
      fluid
  >
    <v-container
        class="fill-height"
    >
      <v-container>
        <v-row align="center" justify="center">
          <v-progress-circular
              indeterminate
              :size="128"
              :width="8"
          ></v-progress-circular>
        </v-row>
        <v-row
            class="py-8 text-h6"
            align="center"
            justify="center"
        >
        </v-row>
      </v-container>
    </v-container>
  </v-container>

  <v-container v-if="!loading">
    <v-row align="center" justify="center">
      <video
          ref="video"
          id="video"
          :src="videoList[currentVideoIndex]"
          class="video-fill"
          autoplay
          controls
          preload="auto"
          playsinline
          @ended="changeVideo"
      ></video>
    </v-row>
    <v-row align="center" justify="center" class="pt-6 text-h6">
        최대 1분 정도 소요될 수 있어요.
    </v-row>
    <v-row align="center" justify="center">
      그동안 릴스해커의 꿀팁들을 만나보세요!
    </v-row>
  </v-container>
</template>

<style scoped>
.video-fill {
  width: 100%; /* 모바일 화면에서는 영상의 너비를 100%로 설정 */
  object-fit: contain; /* 영상의 비율을 유지 */
}

/* PC 화면에서는 영상의 높이를 75vh로 설정 */
@media (min-width: 600px) {
  .video-fill {
    height: 75vh;
    width: auto; /* 너비를 자동으로 설정하여 비율을 유지 */
  }
}

</style>