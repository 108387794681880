<script>
import {addLineBreaks, formatNumber, openUrlInNewTab} from "@/utils";
import createApiService from "@/apiService";

export default {
  name: "PCCreatorDetailDialog",
  props: ['creatorInfo'],
  methods: {formatNumber, openUrlInNewTab, addLineBreaks,
    loadContent() {
      if (this.contentLoading) return;
      this.contentLoading = true;

      this.api.get(`/api/hot-short-form/creator/${this.creatorInfo.creatorId}/content?sort=${this.selectedSortType}&pageNo=${this.pageNo}`)
          .then((response) => {
            if (response.data.data.length < 12) {
              this.loadMoreEnabled = false;
            }
            response.data.data.forEach((content) => {
              this.contentList.push(content)
            })
          })
          .finally(() => {
            this.contentLoading = false;
          })
    },
    loadNextPage() {
      if (this.contentLoading) return;
      this.pageNo++;
      this.loadContent();
    }
  },
  mounted() {
    this.observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && this.loadMoreEnabled) {
        this.loadNextPage();
      }
    });
    this.observer.observe(this.$refs.loadMoreRef);
  },
  data: () => ({
    sortOptions: [
      {text: '날짜', key: 'UPLOADED_AT'},
      {text: '조회수', key: 'PLAY_COUNT'},
      {text: '좋아요', key: 'LIKE_COUNT'},
      {text: '댓글', key: 'COMMENT_COUNT'},
    ],
    selectedSortType: 'UPLOADED_AT',
    loadMoreEnabled: true,
    contentLoading: false,
    pageNo: 1,
    contentList: [],
  }),
  created() {
    this.api = createApiService(this.$store, this.$router);
    this.loadContent();
  },
  watch: {
    selectedSortType() {
      this.contentList = [];
      this.loadMoreEnabled = true;
      this.pageNo = 1;
      this.loadContent();
    },
    loadMoreEnabled() {
      this.$nextTick(() => {
        if (this.loadMoreEnabled) {
          this.observer.observe(this.$refs.loadMoreRef);
        }
      });
    },
  }
}
</script>

<template>
  <v-card variant="flat">
    <template v-slot:append>
      <v-btn
          icon="mdi-close"
          @click="$emit('closeDialog')"
          variant="text"
      >
      </v-btn>
    </template>
    <v-row class="px-5" v-if="creatorInfo != null">
      <v-col cols="2" @click="openUrlInNewTab(`https://www.instagram.com/${creatorInfo.username}`)">
        <v-container>
          <v-img
              class="bg-white rounded-pill clickable"
              :aspect-ratio="1"
              :src="creatorInfo.profileImageUrl"
              cover
          ></v-img>
        </v-container>
      </v-col>
      <v-col cols="4" align-self="center" @click="openUrlInNewTab(`https://www.instagram.com/${creatorInfo.username}`)">
        <v-card-title class="text-h4">
          {{ creatorInfo.username }}
        </v-card-title>
        <v-card-subtitle>
          {{ creatorInfo.subname }}
        </v-card-subtitle>
        <v-card-text v-if="creatorInfo.description !== null">
          <div v-html="addLineBreaks(creatorInfo.description)"></div>
        </v-card-text>
      </v-col>
      <v-col cols="6" align-self="center" class="text-h4">
        <v-card-text class="mt-4 text-h6">
          <v-row>
            <v-col cols="6">
              최근 평균 조회수 : {{ formatNumber(creatorInfo.averagePlayCount) }}
            </v-col>
            <v-col cols="6">
              최근 평균 좋아요 : {{ formatNumber(creatorInfo.averageLikeCount) }}
            </v-col>
            <v-col cols="6">
              최근 평균 댓글 : {{ formatNumber(creatorInfo.averageCommentCount) }}
            </v-col>
            <v-col cols="6">
              팔로워 : {{ formatNumber(creatorInfo.followerCount) }}
            </v-col>
          </v-row>
          <v-row>
            <v-col offset="9" cols="3" class="justify-end">
              <v-select
                  :items="sortOptions"
                  item-title="text"
                  item-value="key"
                  v-model="selectedSortType"
                  density="compact"
                  label="정렬 옵션"
                  variant="outlined"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>
<!--    콘텐츠 리스트 -->
    <v-row class="pa-5">
      <v-col cols="2" v-for="content in contentList" :key="content">
        <v-card
            @click="openUrlInNewTab(`https://www.instagram.com/p/${content.shortCode}`)"
            class="hot-content-card"
            variant="flat"
        >
          <v-img
              id="content-image"
              :src="content.thumbnailUrl"
              style="border-radius: 2%; aspect-ratio: 9 / 16;"
              class="clickable"
              cover
          >
            <v-container
                class="overlay-top"
            >
              <v-row no-gutters justify="end">
                <v-col cols="auto">
                  {{ content.date }}
                </v-col>
              </v-row>
            </v-container>
            <v-container
                class="overlay-bottom"
            >
              <v-row no-gutters>
                <v-col cols="auto">
                  <v-icon>
                    mdi-play
                  </v-icon>
                </v-col>
                <v-col cols="auto">
                  {{ formatNumber(content.playCount) }}
                </v-col>
              </v-row>
            </v-container>
          </v-img>

          <v-card-title class="pt-3 px-0">
            <v-row dense="true">
              <v-col cols="6" align-self="start">
                <v-btn
                    block
                    :ripple="false"
                    prepend-icon="mdi-heart-outline"
                    variant="flat"
                    size="large"
                    density="compact"
                >
                  {{ formatNumber(content.likeCount) }}
                </v-btn>
              </v-col>
              <v-col cols="6" align-self="start">
                <v-btn
                    block
                    :ripple="false"
                    prepend-icon="mdi-comment-outline"
                    variant="flat"
                    size="large"
                    density="compact"
                >
                  {{ formatNumber(content.commentCount) }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-container>
      <div v-if="this.loadMoreEnabled" ref="loadMoreRef"></div> <!-- 관찰 대상 요소 -->
    </v-container>
  </v-card>
</template>

<style scoped>
.overlay-bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  font-weight: bold;
  transform: translateX(-50%);
  color: white;
  padding: 8px;
  border-radius: 5px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent); /* 그라데이션 효과 */
}

.overlay-top {
  position: absolute;
  top: 0;
  left: 50%;
  font-weight: bold;
  transform: translateX(-50%);
  color: white;
  padding: 8px;
  border-radius: 5px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), transparent); /* 그라데이션 효과 */
}

.text-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.clickable {
  cursor: pointer;
}

</style>