<script>
import createApiService from "@/apiService";
import {convertDateFormat} from "@/utils";

export default {
  name: "PCDailyStatsDetailDialog",
  data() {
    return {
      recentStats: [],
    }
  },
  methods: {
    convertDateFormat,
    loadRecentDailyStats() {
      this.api.get('/api/my-channel/recent-stats')
          .then((response) => {
            switch (response.data.code) {
              case 'SUCCESS': {
                this.recentStats = response.data.data
                break;
              }
              case 'CREATOR_NOT_LINKED': {
                alert("연동된 계정이 없습니다.")
                break;
              }
              case 'BEFORE_FIRST_COLLECTION': {
                alert("채널 정보를 수집 중입니다. 잠시 후 다시 시도해주세요.")
                break;
              }
              default: {
                alert("문제가 발생했습니다. 지속적으로 발생 시 서비스 센터에 문의해주세요.")
              }
            }
          })
          .catch(() => {
            alert("문제가 발생했습니다. 지속적으로 발생 시 서비스 센터에 문의해주세요.")
          })
    },
  },
  created() {
    this.api = createApiService(this.$store, this.$router);
    this.loadRecentDailyStats()
  },
}
</script>

<template>
  <v-card
      title="일간 릴스 성과"
      subtitle="매일 내가 릴스를 얼마나 잘했는지 확인해보세요!"
  >
    <template v-slot:append>
      <v-btn
          icon="mdi-close"
          @click="$emit('closeDialog')"
          variant="text"
      >
      </v-btn>
    </template>
    <v-card-text>
      <v-container>
        <v-table density="compact">
          <thead class="bg-grey-lighten-3" >
          <tr>
            <th class="text-center">날짜</th>
            <th class="text-center">조회수</th>
            <th class="text-center">좋아요</th>
            <th class="text-center">댓글</th>
            <th class="text-center">공유</th>
            <th class="text-center">저장</th>
            <th class="text-center">팔로워</th>
            <th class="text-center">업로드</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="stat in recentStats" :key="stat">
            <td class="text-center"> {{ convertDateFormat(stat.date) }} </td>
            <td class="text-center"> {{ stat.playCount }} </td>
            <td class="text-center"> {{ stat.likeCount }} </td>
            <td class="text-center"> {{ stat.commentCount }} </td>
            <td class="text-center"> {{ stat.shareCount }} </td>
            <td class="text-center"> {{ stat.saveCount }} </td>
            <td class="text-center"> {{ stat.followIncreaseCount }} </td>
            <td class="text-center">
              <v-icon color="green" v-if="stat.uploadReels">
                mdi-check-circle
              </v-icon>
              <v-icon color="red" v-else>
                mdi-close-circle
              </v-icon>
            </td>
          </tr>
          </tbody>
        </v-table>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<style scoped>

</style>