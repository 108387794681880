<script>
export default {
  name: "MobileMembershipLevelCard",
  props: ['membershipLevel', 'currentMembershipLevel']
}
</script>

<template>
  <v-card class="mx-auto py-2">
    <v-card-title class="text-h5 font-weight-bold">
      {{ membershipLevel.name }}
    </v-card-title>
    <v-card-subtitle>
      {{ membershipLevel.description }}
    </v-card-subtitle>
    <v-card-text class="d-flex align-center justify-center flex-column">
      <div class="discount-price">
        <span class="text-h4 font-weight-bold">{{ membershipLevel.price }}</span>
        <span class="text-h6">{{ membershipLevel.pricePostfix }}</span>
      </div>
    </v-card-text>
    <v-card-actions class="justify-center" v-if="currentMembershipLevel !== -1">
      <v-btn
          v-if="currentMembershipLevel === 0 && membershipLevel.level !== 0"
          variant="tonal"
          color="primary"
          block
          @click="$emit('subscribe')"
      >
        구독하기
      </v-btn>
      <v-btn
          v-else-if="currentMembershipLevel < membershipLevel.level"
          variant="tonal"
          color="primary"
          block
          @click="$emit('upgrade')"
      >
        업그레이드
      </v-btn>
      <v-btn
          v-else-if="currentMembershipLevel > membershipLevel.level"
          variant="tonal"
          color="primary"
          block
          @click="$emit('downgrade')"
      >
        다운그레이드
      </v-btn>
      <v-btn
          v-else-if="currentMembershipLevel === membershipLevel.level"
          variant="tonal"
          block
          disabled
      >
        이용 중
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-row
          v-for="benefit in membershipLevel.benefits"
          :key="benefit.name"
      >
        <v-col cols="7">
          <div class="font-weight-bold mb-2" style="font-size:16px">
            {{ benefit.name }}
          </div>
          <div
              v-for="subtitle in benefit.subtitleList" :key="subtitle"
          >
            - {{ subtitle }}
          </div>
        </v-col>
        <v-col cols="5" class="font-weight-bold text-center align-self-center" style="font-size:18px">
          <div v-if="benefit.justUseBoolean">
            <v-icon
                v-if="benefit.descriptionValue"
                color="green"
            >
              mdi-check-circle
            </v-icon>
            <v-icon
                v-else
                color="red"
            >
              mdi-close-circle
            </v-icon>
          </div>
          <div v-else>
            {{ benefit.descriptionValue }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped>

</style>