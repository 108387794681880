<script>

export default {
  name: "PCYesterdayCard",
  props: [
    "yesterdayStats"
  ],
}
</script>

<template>
  <v-card variant="flat">
    <v-row class="align-center justify-space-between">
      <v-col cols="auto">
        <v-card-title class="text-h5">
          어제 내 릴스 성과
          <v-tooltip text="어제의 내 성과가 표시됩니다.">
            <template v-slot:activator="{ props }">
              <v-btn
                  icon="mdi-exclamation"
                  density="comfortable"
                  v-bind="props"
                  size="x-small"
                  class="mb-1"
                  variant="outlined">
              </v-btn>
            </template>
          </v-tooltip>
        </v-card-title>
      </v-col>
      <v-col cols="auto" class="pr-7">
        <v-btn color="primary" variant="outlined" @click="$emit('pc-daily-stats-dialog-on')">
          일간 기록 더보기
        </v-btn>
      </v-col>
    </v-row>
    <v-card-text class="mt-4 text-h6" v-if="yesterdayStats">
      <v-row>
        <v-col cols="4">
          조회수 증가 : {{ yesterdayStats.playCount }}
        </v-col>
        <v-col cols="4">
          좋아요 증가 : {{ yesterdayStats.likeCount }}
        </v-col>
        <v-col cols="4">
          댓글 증가 : {{ yesterdayStats.commentCount }}
        </v-col>
        <v-col cols="4">
          공유 증가 : {{ yesterdayStats.shareCount }}
        </v-col>
        <v-col cols="4">
          저장 증가 : {{ yesterdayStats.saveCount }}
        </v-col>
        <v-col cols="4">
          팔로워 증가 : {{ yesterdayStats.followIncreaseCount }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="mt-4 text-h6" v-else>
      내일부터 볼 수 있어요!
    </v-card-text>
  </v-card>
</template>

<style scoped>

</style>