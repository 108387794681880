<script>
import {addLineBreaks} from "@/utils";

export default {
  name: "MobileProfile",
  props: ["myChannelInfo", "yesterdayStats"],
  methods: {
    addLineBreaks,
  },
}
</script>

<template>
  <v-card variant="flat" class="px-2">
    <v-row>
      <v-col cols="5" align-self="center">
        <v-container>
          <v-img
              class="bg-white rounded-pill"
              :aspect-ratio="1"
              :src="myChannelInfo.profileImageUrl"
              cover
          ></v-img>
        </v-container>
      </v-col>
      <v-col cols="7" align-self="center">
        <v-card-title class="text-h5">
          {{ myChannelInfo.name }}
        </v-card-title>
        <v-card-subtitle v-if="myChannelInfo.subname !== null">
          {{ myChannelInfo.subname }}
        </v-card-subtitle>
        <v-card-text v-if="myChannelInfo.description !== null">
          <div v-html="addLineBreaks(myChannelInfo.description)"></div>
        </v-card-text>
      </v-col>
    </v-row>
    <v-row class="align-center justify-space-between py-0 my-0">
      <v-col cols="auto">
        <v-card-title class="text-h6">
          어제 내 릴스 성과
        </v-card-title>
      </v-col>
      <v-col class="text-end mx-1">
        <v-btn
            color="primary"
            variant="outlined"
            @click="$emit('mobile-daily-stats-dialog-on')"
        >
          일간 기록
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-0 pt-0 mb-1" v-if="yesterdayStats">
      <v-col cols="4" class="ma-0 pa-0">
        <v-btn
            size="large"
            density="compact"
            variant="text"
            prepend-icon="mdi-play-circle-outline"
            block
        >
          {{ yesterdayStats.playCount }}
        </v-btn>
      </v-col>
      <v-col cols="4" class="ma-0 pa-0">
        <v-btn
            size="large"
            density="compact"
            variant="text"
            prepend-icon="mdi-heart-outline"
            block
        >
          {{ yesterdayStats.likeCount }}
        </v-btn>
      </v-col>
      <v-col cols="4" class="ma-0 pa-0">
        <v-btn
            size="large"
            density="compact"
            variant="text"
            prepend-icon="mdi-comment-outline"
            block
        >
          {{ yesterdayStats.commentCount }}
        </v-btn>
      </v-col>
      <v-col cols="4" class="ma-0 pa-0">
        <v-btn
            block
            density="compact"
            size="large"
            variant="text"
            prepend-icon="mdi-share-outline"
        >
          {{ yesterdayStats.shareCount }}
        </v-btn>
      </v-col>
      <v-col cols="4" class="ma-0 pa-0">
        <v-btn
            block
            density="compact"
            size="large"
            variant="text"
            prepend-icon="mdi-bookmark-outline"
        >
          {{ yesterdayStats.saveCount }}
        </v-btn>
      </v-col>
      <v-col cols="4" class="ma-0 pa-0">
        <v-btn
            size="large"
            density="compact"
            variant="text"
            prepend-icon="mdi-account-multiple-outline"
            block
        >
          {{ yesterdayStats.followIncreaseCount }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-0 pt-0 mb-1" v-else>
      <v-col class="mx-4 py-0">
        내일부터 볼 수 있어요!
      </v-col>
    </v-row>
  </v-card>
</template>

<style scoped>

</style>