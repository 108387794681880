<script>
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip} from 'chart.js'
import {Chart} from 'vue-chartjs'
import {useDisplay} from "vuetify";

ChartJS.register(Title, Tooltip, Legend, BarElement, LineElement, PointElement, LinearScale, CategoryScale)

export default {
  name: 'FollowerTrendChart',
  components: {Chart},
  props: ['followerTrendList'],
  setup() {
    const { smAndDown, smAndUp } = useDisplay()
    return { smAndDown, smAndUp }
  },
  data() {
    return {
      selected: 'followerAbs',
      incrementData: null,
      followerData: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          x: {
            type: 'category',
          },
          y: {
            type: 'linear',
            position: 'left',
          },
        },
      },
    }
  },
  created() {
    this.prepareChartData()
  },
  methods: {
    prepareChartData() {
      this.followerData = {
        labels: this.followerTrendList.map(item => item.date),
        datasets: [
          {
            type: 'line',
            label: '총 팔로워 수',
            backgroundColor: '#FFFFFF',
            borderColor: '#8950FF',
            borderWidth: 1.5,
            fill: false,
            data: this.followerTrendList.map(item => item.followerCount),
            yAxisID: 'y',
          }
        ]
      }
      this.incrementData = {
        labels: this.followerTrendList.map(item => item.date),
        datasets: [
          {
            type: 'line',
            label: '팔로워 증감',
            backgroundColor: '#FFFFFF',
            borderColor: '#8950FF',
            borderWidth: 1.5,
            fill: false,
            data: this.followerTrendList.map(item => item.followIncreaseCount),
            yAxisID: 'y',
          }
        ]
      }
    },
  }
}
</script>
<template>
  <v-row justify="end">
    <v-btn-toggle
        :class="smAndDown ? 'pr-1' : 'pr-9'"
        v-model="selected"
        color="primary"
        group
        density="compact"
        mandatory
    >
      <v-btn
          size="small"
          density="compact"
          value="increment"
      >
        팔로워 증감
      </v-btn>

      <v-btn
          size="small"
          density="compact"
          value="followerAbs"
      >
        팔로워 수
      </v-btn>
    </v-btn-toggle>
  </v-row>
  <v-row>
    <Chart v-if="incrementData && selected === 'increment' " :data="incrementData" :options="chartOptions"/>
    <Chart v-if="followerData && selected === 'followerAbs' " :data="followerData" :options="chartOptions"/>
  </v-row>
</template>
<style scoped>
</style>