<script>
export default {
  name: "PCMembershipBenefitTable",
  props: ['membershipList']
}
</script>

<template>
  <v-container>
    <v-row class="pb-5">
      <v-col></v-col>
      <v-col
          v-for="membership in membershipList"
          :key="membership.name"
          class="text-h5 font-weight-bold text-center"
      >
        <div>
          {{ membership.name }}
        </div>
        <!--        <div>-->
        <!--          <v-btn-->
        <!--              v-if="membership.name !== 'Free'"-->
        <!--              size="small"-->
        <!--              variant="tonal"-->
        <!--              color="primary"-->
        <!--          >-->
        <!--            구독하기-->
        <!--          </v-btn>-->
        <!--        </div>-->
      </v-col>
    </v-row>
    <v-divider
        thickness="2"
        class="pb-5"
    />
    <v-row
        v-for="(benefit, benefitIndex) in membershipList[0].benefits" :key="benefit"
    >
      <v-col>
        <div class="text-h6 font-weight-bold">
          {{ benefit.name }}
        </div>
        <div
            v-for="subtitle in benefit.subtitleList" :key="subtitle"
        >
          - {{ subtitle }}
        </div>
      </v-col>
      <v-col
          class="font-weight-bold text-h6 text-center align-self-center"
          v-for="(membership) in membershipList" :key="membership"
      >
        <div v-if="membership.benefits[benefitIndex].justUseBoolean">
          <v-icon
              v-if="membership.benefits[benefitIndex].descriptionValue"
              color="green"
          >
            mdi-check-circle
          </v-icon>
          <v-icon
              v-else
              color="red"
          >
            mdi-close-circle
          </v-icon>
        </div>
        <div v-else>
          {{ membership.benefits[benefitIndex].descriptionValue }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>

</style>