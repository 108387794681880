<script>
import createApiService from "@/apiService";

export default {
  name: "UsageHistoryDialog",
  props: ['targetServiceInfo'],
  data: () => ({
    usageHistory: [],
  }),
  methods: {
    toHistoryDetail(uuid) {
      this.$router.push(this.targetServiceInfo.detailViewPath + '?uuid=' + uuid)
    },
    loadRecentUsage() {
      this.api.get(`/api/ai/${this.targetServiceInfo.apiPath}/recent`)
          .then((response) => {
            this.usageHistory = response.data.data;
          })
    }
  },
  created() {
    this.api = createApiService(this.$store, this.$router);
    this.loadRecentUsage()
  }
}
</script>

<template>
  <v-card variant="flat" class="pb-3">
    <v-card-title class="mx-2">
      <v-row align="center" justify="center" class="bg-white">
        <v-col cols="6">
          {{ this.targetServiceInfo.name }} 사용내역
        </v-col>
        <v-col cols="6" class="text-end">
          <v-btn
              size="large"
              icon="mdi-close"
              @click="$emit('closeDialog')"
              variant="text"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-row class="ma-0">
      <v-list
          style="width: 100%"
      >
        <v-list-item
            class="py-3"
            v-for="history in usageHistory"
            :key="history"
            @click="toHistoryDetail(history.uuid)"
            style="width: 100%"
        >
          <v-card variant="text">
            <v-card-text class="truncate-text">
              {{ history.userInput }}
            </v-card-text>
            <v-card-subtitle>
              {{ history.completedDateTime }}
            </v-card-subtitle>
          </v-card>
        </v-list-item>
      </v-list>
    </v-row>
  </v-card>
</template>

<style scoped>
.truncate-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  margin-bottom: 10px;
}
</style>