<script>
import createApiService from "@/apiService";

export default {
  name: "LoginDialogCard",
  data() {
    return {
      appUrl: process.env.VUE_APP_URL,
      facebookAppId: process.env.VUE_APP_FB_APP_ID,
      isProcessingLogin: false,
    }
  },
  methods: {
    toPrivacy() {
      this.$store.commit('closeLoginDialog');
      this.$router.push('/creator/privacy');
    },
    toTerms() {
      this.$store.commit('closeLoginDialog');
      this.$router.push('/creator/terms');
    },
    googleLogin() {
      let url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=691410230621-og02jfgto69ke4j0kmuk3kuq0u2u26rr.apps.googleusercontent.com&redirect_uri=${this.appUrl}/creator/login/google&response_type=code&scope=email profile`;
      window.open(url, 'googleLogin', 'width=500, height=700, left=500, top=200');
    },
    metaLogin() {
      let url = `https://www.facebook.com/v17.0/dialog/oauth?client_id=${this.facebookAppId}&redirect_uri=${this.appUrl}/creator/login/meta&state=blah&response_type=token&scope=email`;
      window.open(url, 'metaLogin', 'width=500, height=700, left=500, top=200');
    },
    handleAuthMessage(event) {
      if (event.origin !== this.appUrl) return;
      if (this.isProcessingLogin) return;
      this.isProcessingLogin = true;

      let provider, accessToken;
      const { type, code } = event.data;

      if (type === 'googleAuthCode') {
        provider = 'GOOGLE';
        accessToken = code;
      } else if (type === 'metaAuthCode') {
        provider = 'META';
        accessToken = code;
      } else {
        console.error('Unknown auth type');
        this.isProcessingLogin = false;
        return;
      }

      this.api.post(`/auth-api/member/login?accessToken=${accessToken}&provider=${provider}`)
          .then(response => {
            this.$store.commit('setRefreshToken', response.data.refreshToken);
            this.$store.commit('setAccessToken', response.data.accessToken);
            this.$store.commit('setIsLogin', true);
            this.$emit('loginSuccess');
            this.$router.push('/');
          })
          .catch(() => {
            alert("로그인 중 문제가 발생했습니다. 지속적으로 발생 시 서비스 센터에 문의해주세요.");
          })
          .finally(() => {
            this.isProcessingLogin = false;
            this.$store.commit('closeLoginDialog');
          });
    }
  },
  mounted() {
    window.addEventListener('message', this.handleAuthMessage);
  },
  beforeUnmount() {
    window.removeEventListener('message', this.handleAuthMessage);
  },
  created() {
    this.api = createApiService(this.$store, this.$router);
  }
}
</script>

<template>
  <v-card
      class="align-center py-6"
      variant="flat"
      rounded="lg"
  >
    <v-container>
      <v-row align="center" justify="center">
        <v-img :src="require('@/assets/new_logo2.png')" width="280"></v-img>
      </v-row>
    </v-container>
    <v-card-title class="pb-5"  style="text-align: center;">
      <strong>현재 8000+이 넘는 크리에이터</strong>가<br>
      숏부스트를 사용하고 있습니다.
    </v-card-title>
    <v-card-text block class="text">
      <v-btn variant="outlined" class="d-flex" width="280" size="large" @click="metaLogin">
        <template v-slot:prepend>
          <v-avatar>
            <v-img :src="require('@/assets/icons8-facebook-40.svg')" class="align-self-center"></v-img>
          </v-avatar>
        </template>
        페이스북으로 계속하기
      </v-btn>
      <v-btn variant="outlined" class="d-flex mt-3" width="280" size="large" @click="googleLogin">
        <template v-slot:prepend>
          <v-avatar>
            <v-img :src="require('@/assets/icons8-google.svg')" class="align-self-center"></v-img>
          </v-avatar>
        </template>
        구글로 계속하기
      </v-btn>
    </v-card-text>
    <v-card-subtitle class="pt-5 text-center">
      로그인하면 하단 정책에
      <br>
      모두 동의한 것으로 간주합니다.
    </v-card-subtitle>
    <v-card-actions>
      <v-btn @click="toTerms">
        이용약관
      </v-btn>
      <v-btn @click="toPrivacy">
        개인정보처리방침
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>

</style>