<script>
export default {
  name: "CreatorDetailUnavailableNoticeDialogCard"
}
</script>

<template>
  <v-card
      min-width="20vw"
      title="사용 불가능"
  >
    <v-card-text>
      Basic 이상의 멤버십 등급이 필요합니다.
      <br>
      멤버십을 구독해주세요.
    </v-card-text>
    <template v-slot:append>
      <v-btn
          icon="mdi-close"
          @click="$emit('close-creator-detail-unavailable-notice-dialog')"
          variant="text"
      >
      </v-btn>
    </template>
    <template v-slot:actions>
      <v-btn
          block
          class="ms-auto"
          color="primary"
          to="/membership/subscribe"
      >
        멤버십 구독하기
      </v-btn>
    </template>
  </v-card>
</template>

<style scoped>

</style>