<script>
import createApiService from "@/apiService";
import {openUrlInNewTab} from "@/utils";
import LoginDialogCard from "@/components/member/LoginDialogCard.vue";
import MyAccountInfo from "@/components/member/MyAccountInfo.vue";
import AppFooter from "@/AppFooter.vue";

export default {
  name: 'App',
  components: {AppFooter, MyAccountInfo, LoginDialogCard},
  data() {
    return {
      myAccountInfoDialog: false,
      companyInfoDialog: false,
      drawer: false,
      selected: [],
    }
  },
  methods: {
    toMyChannel() {
      this.api.get("/api/my-channel")
          .then((response) => {
            if (response.data.code === 'CREATOR_NOT_LINKED') {
              this.$router.push('/enroll-my-channel')
            } else if (response.data.code === "SUCCESS") {
              this.$router.push('/my-channel')
            } else {
              alert("문제가 발생했습니다. 지속적으로 발생 시 서비스 센터에 문의해주세요.")
            }
          })
          .catch(() => {
            alert("문제가 발생했습니다. 지속적으로 발생 시 서비스 센터에 문의해주세요.")
          })
    },
    openMainPage() {
      this.$router.push('/');
    },
    openCustomPage() {
      openUrlInNewTab("http://pf.kakao.com/_xfqBEG")
    },
    openGuide() {
      openUrlInNewTab("https://loat.notion.site/5da076c728b443cc9a296ef280438f51")
    },
    toSubscribePage() {
      this.$router.push("/membership/subscribe")
    },
    logout() {
      this.api.post('/api/member/logout')
          .then(() => {
            this.$store.commit('logOut');
            this.openMainPage()
          })
          .catch(() => {
            alert("문제가 발생했습니다. 서비스 센터에 문의해주세요")
          }).finally(() => {
            this.$store.commit('logOut');
            this.openMainPage()
      })
    }
  },
  created() {
    this.api = createApiService(this.$store, this.$router);
  },
  computed: {
    isLogin: {
      get() {
        return this.$store.state.isLogin
      },
    }
  },
}
</script>


<template>
  <v-app id="inspire">
    <v-navigation-drawer
        v-model="drawer"
        color="background-grey"
        border="right"
    >
      <v-list density="compact">
        <v-list-item>
          <template v-slot:append>
            <v-btn
                color="text-secondary"
                icon="mdi-chevron-double-left"
                variant="text"
                @click="drawer = !drawer"
            ></v-btn>
          </template>
        </v-list-item>
        <v-list-item class="mx-3 mb-5" to="/" prepend-icon="mdi-home-outline">
          <v-list-item-title class="pt-1">홈</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-subheader class="mx-3 mt-5">
          터지는 주제 찾기
        </v-list-subheader>
        <v-list-item class="mx-3" to="/creator/new-content-search" prepend-icon="mdi-magnify">
          <v-list-item-title class="pt-1">영상 검색 하기</v-list-item-title>
        </v-list-item>
        <v-list-item class="mx-3" to="/creator/topic-suggestion-ai" prepend-icon="mdi-star-outline">
          <v-list-item-title class="pt-1">주제 추천 AI</v-list-item-title>
        </v-list-item>
        <v-list-subheader class="mx-3 mt-5">
          터지는 영상 만들기
        </v-list-subheader>
        <v-list-item class="mx-3" to="/creator/hookingment-ai" prepend-icon="mdi-hook">
          <v-list-item-title class="pt-1">후킹 멘트 AI</v-list-item-title>
        </v-list-item>
        <v-list-subheader class="mx-3 mt-5">
          내 영상 검사받기
        </v-list-subheader>
        <v-list-item class="mx-3" to="/creator/script-feedback-ai" prepend-icon="mdi-text-box-check-outline">
          <v-list-item-title class="pt-1">대본 피드백 AI</v-list-item-title>
        </v-list-item>
        <v-list-subheader class="mx-3 mt-5">
          내 계정 관리하기
        </v-list-subheader>
        <v-list-item class="mx-3" prepend-icon="mdi-account-outline" @click="this.toMyChannel">
          <v-list-item-title class="pt-1">
            내 계정 분석
            <v-chip
                class="ml-2"
                color="primary"
                size="x-small"
                label
            >
              Beta
            </v-chip>
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="mx-3 mt-10" to="/membership/subscribe">
          <v-list-item-title class="pt-1">멤버십</v-list-item-title>
        </v-list-item>
        <v-list-item class="mx-3" @click="openGuide">
          <v-list-item-title class="pt-1">이용가이드</v-list-item-title>
        </v-list-item>
        <v-list-item class="mx-3" @click="openCustomPage">
          <v-list-item-title class="pt-1">문의하기</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
        class="pl-2 pt-2 pr-5"
        flat="true"
    >
      <v-app-bar-nav-icon
          v-if="!drawer"
          @click="drawer = !drawer"
          color="text-secondary"
      >
      </v-app-bar-nav-icon>
      <template v-slot:append>
        <v-menu v-if="isLogin">
          <template v-slot:activator="{ props }">
            <v-btn
                size="large"
                v-bind="props"
                icon ="mdi-account-outline"
            >
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-btn
                  block
                  variant="text"
                  @click="toSubscribePage"
              >
                멤버십
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn
                  block
                  variant="text"
                  @click="myAccountInfoDialog = true"
              >
                계정정보
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn
                  block
                  variant="text"
                  @click="logout"
              >
                로그아웃
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
            v-else
            variant="outlined"
            color="text-secondary"
            @click="$store.state.loginDialog = true"
        >
          로그인/회원가입
        </v-btn>
      </template>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer app absolute class="py-5">
      <AppFooter
          @open-company-info-dialog="this.companyInfoDialog = true"
      />
    </v-footer>

    <v-dialog
        v-model="this.companyInfoDialog"
        width="auto"
    >
      <v-card
          variant="flat"
          rounded="lg"
          class="pa-5"
      >
        <v-card-text
            class="pa-5 font-weight-bold"
        >
          <div class="pt-2">
            상호명 : 로아트 LAB
          </div>
          <div class="pt-2">
            사업자 등록번호 : 849-39-01234
          </div>
          <div class="pt-2">
            주소 : 화성시 봉담읍 수영로 61-7, 202-1101
          </div>
          <div class="pt-2">
            대표자 : 구형석
          </div>
          <div class="pt-2">
            이메일 : loatcorp@gmail.com
          </div>
          <div class="pt-2">
            대표 전화번호 : 070-8064-7363
          </div>

        </v-card-text>
        <v-card-actions>
          <v-btn
              block
              color="primary"
              variant="outlined"
              size="large"
              @click="this.companyInfoDialog = false"
          >
            확인
          </v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>

    <v-dialog
        v-model="$store.state.loginDialog"
        width="auto"
    >
      <LoginDialogCard
          @login-success.once="$store.state.loginDialog = false"
      ></LoginDialogCard>
    </v-dialog>

    <v-dialog
        v-model="this.myAccountInfoDialog"
        width="auto"
    >
      <MyAccountInfo
          @close-my-info-dialog="this.myAccountInfoDialog = false"
      />
    </v-dialog>
  </v-app>
</template>

<style>
.v-slide-group__content {
  justify-content: center !important;
}

.v-select__selection-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

</style>
