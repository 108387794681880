<script>
import {addLineBreaks} from "@/utils";

export default {
  name: "PCProfile",
  props: [
    "myChannelInfo"
  ],
  methods: {addLineBreaks}
}
</script>

<template>
  <v-card variant="flat">
    <v-row>
      <v-col cols="5">
        <v-container>
          <v-img
              class="bg-white rounded-pill clickable"
              :aspect-ratio="1"
              :src="myChannelInfo.profileImageUrl"
              cover
          ></v-img>
        </v-container>
      </v-col>
      <v-col cols="7" align-self="center">
        <v-card-title class="text-h4">
          {{ myChannelInfo.name }}
        </v-card-title>
        <v-card-subtitle v-if="myChannelInfo.subname !== null">
          {{ myChannelInfo.subname }}
        </v-card-subtitle>
        <v-card-text v-if="myChannelInfo.description !== null">
          <div v-html="addLineBreaks(myChannelInfo.description)"></div>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<style scoped>

</style>