import {createStore} from "vuex";

const store = createStore({
    state() {
        let accessToken = localStorage.getItem('accessToken2');
        let refreshToken = localStorage.getItem('refreshToken2');
        let isLogin = (accessToken != null && refreshToken != null)

        return {
            loginDialog: false,
            isLogin: isLogin,
            accessToken: accessToken,
            refreshToken: refreshToken,
        }
    },
    mutations: {
        setRefreshToken(state, refreshToken) {
            state.refreshToken = refreshToken;
            localStorage.setItem('refreshToken2', refreshToken)
        },
        setAccessToken(state, accessToken) {
            state.accessToken = accessToken;
            localStorage.setItem('accessToken2', accessToken)
        },
        setIsLogin(state, isLogin) {
            state.isLogin = isLogin;
        },
        logOut(state) {
            state.isLogin = false;
            state.accessToken = null;
            state.refreshToken = null;
            localStorage.removeItem('refreshToken2');
            localStorage.removeItem('accessToken2');
        },
        openLoginDialog(state) {
            state.loginDialog = true;
        },
        closeLoginDialog(state) {
            state.loginDialog = false;
        }
    }
})

export default store;