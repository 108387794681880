<script>
import {useDisplay} from "vuetify";
import createApiService from "@/apiService";
import UsageLimitDialogCard from "@/components/ai/UsageLimitDialogCard.vue";
import UsageHistoryDialog from "@/components/ai/UsageHistoryDialog.vue";

export default {
  name: "NewMainPage",
  components: {UsageHistoryDialog, UsageLimitDialogCard},
  setup() {
    const {smAndDown, smAndUp} = useDisplay()
    return {smAndDown, smAndUp}
  },
  data() {
    return {
      categoryList: [
        {'name': 'ALL', 'description': '전체'},
        {'name': 'BEAUTY', 'description': '뷰티/미용'},
        {'name': 'LIVING', 'description': '생활/리빙'},
        {'name': 'FOOD', 'description': '음식/맛집'},
        {'name': 'FASHION', 'description': '패션'},
        {'name': 'PARENTING', 'description': '육아/아동'},
        {'name': 'BUSINESS', 'description': '비지니스'},
        {'name': 'TRAVEL', 'description': '여행'},
        {'name': 'DAILY', 'description': '일상'},
        {'name': 'DIGITAL', 'description': '디지털/IT'},
        {'name': 'BOOK', 'description': '도서'},
        {'name': 'EDUCATION', 'description': '교육'},
        {'name': 'BROADCAST', 'description': '방송/연예'},
        {'name': 'INFORMATION', 'description': '정보전달'},
        {'name': 'GAME', 'description': '게임'},
        {'name': 'HEALTH_SPORTS', 'description': '헬스/스포츠'},
        {'name': 'PET', 'description': '반려동물'},
      ],
      activeButton: 1,
      usageLimitDialog: false,
      usageHistoryDialog: false,
      usageHistoryTargetServiceInfo: {},
      monthlyUsageLimit: null,
      monthlyUsageCount: null,

      buttons: [
        {id: 1, icon: "mdi-magnify", text: "영상 검색하기"},
        {id: 2, icon: "mdi-star-outline", text: "주제 추천 AI"},
        {id: 3, icon: "mdi-hook", text: "후킹 멘트 AI"},
        // { id: 4, icon: "mdi-file-document-edit-outline", text: "대본 생성 AI" },
        {id: 5, icon: "mdi-text-box-check-outline", text: "대본 피드백 AI"},
      ],
      forms: [
        {id: 2, label: "본인 계정에 대한 설명 입력 <br> (계정의 카테고리, 컨셉, 타겟, 목적 등)", buttonText: "추천 받기"},
        {id: 3, label: "후킹 멘트 생성을 원하는 주제 입력", buttonText: "생성 하기"},
        // { id: 4, label: "대본 작성을 원하는 주제", buttonText: "작성 하기" },
        {id: 5, label: "피드백을 원하는 대본 입력", buttonText: "피드백 받기"},
      ],
      searchTerm: "",
      formInput: "",
      processing: false
    }
  },
  methods: {
    getUsageAndLimit() {
      this.api.get('/api/membership/usage-limit/ai-service/monthly')
          .then((response) => {
            this.monthlyUsageLimit = response.data.data;
          })
      this.api.get('/api/ai/usage-count/monthly')
          .then((response) => {
            this.monthlyUsageCount = response.data.data;
          })
    },

    toSearchPage() {
      if (this.searchTerm.trim() === "") {
        alert("검색어를 입력해주세요.")
        return;
      }
      if (this.searchTerm.trim().length > 10) {
        alert("검색어는 10자 이내로 입력해주세요.")
        return;
      }
      if (this.searchTerm.trim().includes(" ")) {
        alert("검색어는 한 단어로 입력해주세요.")
        return;
      }
      this.$router.push({name: 'NewContentSearchPage', query: {"searchTerm": this.searchTerm}})
    },
    doAI(buttonId) {
      if (this.formInput === "") {
        alert("내용을 입력해주세요.")
        return;
      }
      if (this.processing) {
        return;
      }
      switch (buttonId) {
        case 2:
          this.suggestTopic()
          break;
        case 3:
          this.generateHookingment()
          break;
        case 5:
          this.feedbackScript()
          break;
        default:
          break;
      }
    },
    openUsageHistoryDialog(buttonId) {
      console.log(buttonId)
      switch ( buttonId ) {
        case 2:
          this.usageHistoryTargetServiceInfo = {
            'name': '주제 추천 AI',
            'apiPath': 'topic-suggestion',
            'detailViewPath': '/creator/topic-suggestion-ai',
          }
          this.usageHistoryDialog = true;
          break
        case 3:
          this.usageHistoryTargetServiceInfo = {
            'name': '후킹 멘트 AI',
            'apiPath': 'hookingment',
            'detailViewPath': '/creator/hookingment-ai',
          }
          this.usageHistoryDialog = true;
          break
        case 5:
          this.usageHistoryTargetServiceInfo = {
            'name': '대본 피드백 AI',
            'apiPath': 'script-feedback',
            'detailViewPath': '/creator/script-feedback-ai',
          }
          this.usageHistoryDialog = true;
          break
      }
    },

    feedbackScript() {
      if (this.formInput.trim().length < 50) {
        alert("최소 50자 이상은 입력해야 합니다.\n더 상세히 입력할 수록 더 좋은 결과를 알려줘요!")
        return;
      }
      if (this.formInput.trim().length > 1000) {
        alert("최대 1000자를 입력할 수 있습니다.\n좀 더 줄여서 입력해주세요! ")
        return;
      }

      const body = {script: this.formInput}
      this.processing = true;
      this.api.post("/api/ai/script-feedback", body)
          .then((response) => {
            switch (response.data.code) {
              case 'EXCEEDED_USAGE_LIMIT': {
                this.usageLimitDialog = true;
                break;
              }
              case 'SUCCESS': {
                let uuid = response.data.data.uuid
                this.$router.push({name: 'ScriptFeedbackAIPage', query: {"uuid": uuid}})
              }
                break;
              default:
                break;
            }
          }).catch(
          // 에러 처리
      )
          .finally(() => {
            this.processing = false;
          })
    },

    generateHookingment() {
      if (this.formInput.trim().length < 5) {
        alert("최소 5자 이상은 입력해야 합니다.\n더 상세히 입력할 수록 더 좋은 결과를 알려줘요!")
        return;
      }
      if (this.formInput.trim().length > 100) {
        alert("최대 100자를 입력할 수 있습니다.\n좀 더 줄여서 입력해주세요! ")
        return;
      }
      const body = {topic: this.formInput}
      this.processing = true;
      this.api.post("/api/ai/hookingment", body)
          .then((response) => {
            switch (response.data.code) {
              case 'EXCEEDED_USAGE_LIMIT': {
                this.usageLimitDialog = true;
                break;
              }
              case 'SUCCESS': {
                let uuid = response.data.data.uuid
                this.$router.push({name: 'HookingmentAIPage', query: {"uuid": uuid}})
              }
                break;
              default:
                break;
            }
          }).catch(
          // 에러 처리
      )
          .finally(() => {
            this.processing = false;
          })
    },

    toSearchPageWithCategory(category) {
      this.$router.push({name: 'NewContentSearchPage', query: {"category": category}})
    },

    suggestTopic() {
      if (this.formInput.trim().length < 10) {
        alert("최소 10자 이상은 입력해야 합니다.\n더 상세히 입력할 수록 더 좋은 결과를 알려줘요!")
        return;
      }
      if (this.formInput.trim().length > 300) {
        alert("최대 300자를 입력할 수 있습니다.\n좀 더 줄여서 입력해주세요! ")
        return;
      }
      const body = {accountDescription: this.formInput}
      this.processing = true;
      this.api.post("/api/ai/topic-suggestion", body)
          .then((response) => {
            switch (response.data.code) {
              case 'EXCEEDED_USAGE_LIMIT': {
                this.usageLimitDialog = true;
                break;
              }
              case 'SUCCESS': {
                let uuid = response.data.data.uuid
                this.$router.push({name: 'TopicSuggestionAIPage', query: {"uuid": uuid}})
              }
                break;
              default:
                break;
            }
          }).catch(
          // 에러 처리
      )
          .finally(() => {
            this.processing = false;
          })
    }
  },
  computed: {
    selectedForm() {
      return this.forms.find(form => form.id === this.activeButton)
    }
  },
  watch: {
    activeButton() {
      if (this.monthlyUsageLimit === null || this.monthlyUsageCount === null) {
        if (this.$store.state.isLogin) { this.getUsageAndLimit() }
      }
      this.formInput = ""
    },
    '$route.query.type'(type) {
      if (type === 'content-search') {
        this.activeButton = 1;
      } else if (type === 'topic-suggestion-ai') {
        this.activeButton = 2;
      } else if (type === 'hookingment-ai') {
        this.activeButton = 3;
      } else if (type === 'script-feedback-ai') {
        this.activeButton = 5;
      }
    }
  },
  created() {
    this.api = createApiService(this.$store, this.$router);
    if (this.$store.state.isLogin) {
      this.getUsageAndLimit()
    }
  },
  beforeMount() {
    const type = this.$route.query.type;
    if (type === 'content-search') {
      this.activeButton = 1;
    } else if (type === 'topic-suggestion-ai') {
      this.activeButton = 2;
    } else if (type === 'hookingment-ai') {
      this.activeButton = 3;
    } else if (type === 'script-feedback-ai') {
      this.activeButton = 5;
    }
  }
}
</script>

<template>
  <v-container
      fluid="true"
      class="fill-height"
      style="padding-bottom: 15vh;"
  >
    <v-row
        align="center"
        justify="center"
        class="fill-height"
    >
      <v-col
          :cols="smAndUp ? 8 : 12"
      >
        <v-card variant="flat" class="pb-5">
          <v-row justify="center">
            <v-col
                :cols="smAndUp ? 8 : 12"
            >
              <v-img :src="require('@/assets/logo_with_title_2.png')"></v-img>
            </v-col>
          </v-row>
          <template v-slot:actions>
            <v-container>
              <v-row justify="center">
                <v-btn
                    variant="outlined"
                    :class="activeButton === button.id ? 'selectedBtn' : 'notSelectedBtn'"
                    v-for="button in buttons"
                    :key="button.id"
                    @click="activeButton = button.id"
                    width="135px"
                >
                  {{ button.text }}
                </v-btn>
              </v-row>
            </v-container>
          </template>
        </v-card>
        <v-container
            v-if="activeButton === 1"
        >
          <v-row
              align="center"
              justify="center"
          >
            <v-col
                :cols="smAndUp ? 9 : 12"
            >
              <v-text-field
                  single-line
                  hide-details
                  v-model="searchTerm"
                  variant="solo"
                  density="default"
                  label="키워드 입력"
                  @keyup.enter="toSearchPage"
              >
                <template v-slot:append-inner>
                  <v-btn
                      color="black"
                      @click="toSearchPage"
                  >
                    검색
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row
              align="center"
              justify="center"
              class="pt-3"
          >
            <v-col cols="12">
              <v-slide-group
                  mobile-breakpoint="xs"
                  style="max-width: 80vw"
              >
                <v-slide-group-item
                    style="max-width: 80vw; justify-content: center !important;"
                    v-for="category in categoryList"
                    :key = "category"
                >
                  <v-btn
                      rounded
                      variant="tonal"
                      size="small"
                      class="mx-1"
                      @click="toSearchPageWithCategory(category.name)"
                  >
                    {{ category.description }}
                  </v-btn>
                </v-slide-group-item>
              </v-slide-group>
            </v-col>
          </v-row>
        </v-container>
        <v-row
            v-else
            align="center"
            justify="center"
        >
          <v-col
              :cols="smAndUp ? 8 : 12"
          >
            <v-form @submit.prevent>
              <v-textarea
                  class="rounded-b-0"
                  variant="outlined"
                  auto-grow="true"
                  single-line="true"
                  hide-details
                  v-model="formInput"
              >
                <template v-slot:label>
                  <div v-html="selectedForm.label"/>
                </template>
              </v-textarea>
              <v-btn
                  class="mt-0 pt-0"
                  color="black"
                  block="true"
                  :loading="processing"
                  @click="doAI(activeButton)"
              >
                {{ selectedForm.buttonText }} <span class="mx-1" v-if="monthlyUsageCount !== null && monthlyUsageLimit !== null">{{ this.monthlyUsageCount }}/{{ this.monthlyUsageLimit }}</span>
                <v-tooltip
                    location="top"
                    text="월 사용량 / 월 최대 사용량 "
                >
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props">
                      mdi-information-outline
                    </v-icon>
                  </template>
                </v-tooltip>
              </v-btn>
              <v-btn
                  class="mt-0 pt-0"
                  block="true"
                  color="primary"
                  :loading="processing"
                  @click="openUsageHistoryDialog(activeButton)"
              >
                사용 내역 확인하기
              </v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
  <v-dialog
      v-model="usageLimitDialog"
      width="auto"
  >
    <UsageLimitDialogCard @close-usage-limit-dialog="usageLimitDialog=false"/>
  </v-dialog>
  <v-dialog
      v-model="usageHistoryDialog"
      :width="smAndUp ? '40vw' : '100vw'"
      scrollable
  >
    <UsageHistoryDialog
        :target-service-info="this.usageHistoryTargetServiceInfo"
        @close-dialog="usageHistoryDialog=false"
    />
  </v-dialog>
</template>

<style>
.selectedBtn {
  background: #8950FF !important;
  border-color: #8950FF !important;
  color: white !important;
  margin-top: 5px !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
  padding-right: 12px !important;
  padding-left: 12px !important;
}

.notSelectedBtn {
  color: #585858 !important;
  margin-top: 5px !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
  padding-right: 12px !important;
  padding-left: 12px !important;
}

</style>