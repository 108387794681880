<script>
import {addLineBreaks, formatNumber, openUrlInNewTab} from "@/utils";
import createApiService from "@/apiService";

export default {
  name: "MobileCreatorDetailDialog",
  props: ['creatorInfo'],
  methods: {formatNumber, openUrlInNewTab, addLineBreaks,
    loadContent() {
      if (this.contentLoading) return;
      this.contentLoading = true;

      this.api.get(`/api/hot-short-form/creator/${this.creatorInfo.creatorId}/content?sort=${this.selectedSortType}&pageNo=${this.pageNo}`)
          .then((response) => {
            if (response.data.data.length < 12) {
              this.loadMoreEnabled = false;
            }
            response.data.data.forEach((content) => {
              this.contentList.push(content)
            })
          })
          .finally(() => {
            this.contentLoading = false;
          })
    },
    loadNextPage() {
      if (this.contentLoading) return;
      this.pageNo++;
      this.loadContent();
    }
  },
  mounted() {
    this.observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && this.loadMoreEnabled) {
        this.loadNextPage();
      }
    });
    this.observer.observe(this.$refs.loadMoreRef);
  },
  data: () => ({
    sortOptions: [
      {text: '날짜', key: 'UPLOADED_AT'},
      {text: '조회수', key: 'PLAY_COUNT'},
      {text: '좋아요', key: 'LIKE_COUNT'},
      {text: '댓글', key: 'COMMENT_COUNT'},
    ],
    selectedSortType: 'PLAY_COUNT',
    loadMoreEnabled: true,
    contentLoading: false,
    pageNo: 1,
    contentList: [],
  }),
  created() {
    this.api = createApiService(this.$store, this.$router);
    this.loadContent();
  },
  watch: {
    selectedSortType() {
      this.contentList = [];
      this.loadMoreEnabled = true;
      this.pageNo = 1;
      this.loadContent();
    },
    loadMoreEnabled() {
      this.$nextTick(() => {
        if (this.loadMoreEnabled) {
          this.observer.observe(this.$refs.loadMoreRef);
        }
      });
    },
  }
}
</script>

<template>
  <v-card>
    <v-card-title>
      <v-row align="end" justify="end" class="bg-white">
        <v-col>
          <v-btn
              icon="mdi-close"
              @click="$emit('closeDialog')"
              variant="text"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row
          v-if="creatorInfo != null"
          align="center"
          justify="center"
          class="clickable"
          @click="openUrlInNewTab(`https://www.instagram.com/${creatorInfo.username}`)"
      >
        <v-col cols="5" align-self="center" @click="openUrlInNewTab(`https://www.instagram.com/${creatorInfo.username}`)"
        >
          <v-container>
            <v-img
                class="bg-white rounded-pill"
                :aspect-ratio="1"
                :src="creatorInfo.profileImageUrl"
                cover
            ></v-img>
          </v-container>
        </v-col>
        <v-col cols="6" align-self="center" @click="openUrlInNewTab(`https://www.instagram.com/${creatorInfo.username}`)">
          <v-card-title class="text-h5">
            {{ creatorInfo.username }}
          </v-card-title>
          <v-card-subtitle>
            {{ creatorInfo.subname }}
          </v-card-subtitle>
        </v-col>
      </v-row>
      <v-row v-if="creatorInfo != null" align="center" justify="center">
        <v-col cols="6" class="my-0">
          <v-btn
              size="large"
              density="compact"
              variant="text"
              prepend-icon="mdi-play-circle-outline"
              block
          >
            {{ formatNumber(creatorInfo.averagePlayCount) }}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              size="large"
              density="compact"
              variant="text"
              prepend-icon="mdi-heart-outline"
              block
          >
            {{ formatNumber(creatorInfo.averageLikeCount) }}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              size="large"
              density="compact"
              variant="text"
              prepend-icon="mdi-comment-outline"
              block
          >
            {{ formatNumber(creatorInfo.averageCommentCount) }}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              size="large"
              density="compact"
              variant="text"
              prepend-icon="mdi-account-multiple-outline"
              block
          >
            {{ formatNumber(creatorInfo.followerCount) }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="justify-end pb-0">
        <v-col cols="5">
          <v-select
              :items="sortOptions"
              item-title="text"
              item-value="key"
              v-model="selectedSortType"
              density="compact"
              label="정렬 옵션"
              variant="outlined"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            v-for="content in contentList"
            :key="content"
            @click="openUrlInNewTab(`https://www.instagram.com/p/${content.shortCode}`)"
        >
          <v-row
              align="center"
              no-gutters="true"
          >
            <v-col cols="7">
              <v-img
                  id="content-image"
                  :src="content.thumbnailUrl"
                  style="border-radius: 2%; aspect-ratio: 9 / 16;"
                  class="clickable"
                  cover
                  @click=openUrlInNewTab(contentUrl)
              >
                <v-container
                    class="overlay-bottom"
                >
                  <v-row no-gutters>
                    <v-col cols="auto">
                      <v-icon>
                        mdi-play
                      </v-icon>
                    </v-col>
                    <v-col cols="auto">
                      {{ formatNumber(content.playCount) }}
                    </v-col>
                  </v-row>
                </v-container>
              </v-img>
            </v-col>
            <v-col cols="5">
              <v-card
                  variant="flat"
              >
                <v-card-item class="px-2 py-0">
                  <label
                      for="followCount"
                      class="text-text-secondary"
                      style="font-size: 14px"
                  >좋아요
                  </label>
                  <v-text-field
                      id="followCount"
                      variant="outlined"
                      density="compact"
                      base-color="text-secondary"
                      readonly="true"
                      hide-details
                  >
                    {{ formatNumber(content.likeCount) }}
                  </v-text-field>
                </v-card-item>
                <v-card-item class="px-2 py-0">
                  <label
                      for="followCount"
                      class="text-text-secondary"
                      style="font-size: 14px"
                  >댓글
                  </label>
                  <v-text-field
                      id="followCount"
                      variant="outlined"
                      density="compact"
                      base-color="text-secondary"
                      readonly="true"
                      hide-details
                  >
                    {{ formatNumber(content.commentCount) }}
                  </v-text-field>
                </v-card-item>
                <v-card-item class="px-2">
                  <label
                      for="uploadDate"
                      class="text-text-secondary"
                      style="font-size: 14px"
                  >업로드 날짜
                  </label>
                  <v-text-field
                      id="uploadDate"
                      variant="outlined"
                      density="compact"
                      base-color="text-secondary"
                      readonly="true"
                      hide-details
                      class="text-body-1"
                  >
                    {{ content.date.substring(2, 10) }}
                  </v-text-field>
                </v-card-item>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-container>
        <div v-if="this.loadMoreEnabled" ref="loadMoreRef"></div> <!-- 관찰 대상 요소 -->
      </v-container>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.overlay-bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  font-weight: bold;
  transform: translateX(-50%);
  color: white;
  padding: 8px;
  border-radius: 5px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent); /* 그라데이션 효과 */
}

.overlay-top {
  position: absolute;
  top: 0;
  left: 50%;
  font-weight: bold;
  transform: translateX(-50%);
  color: white;
  padding: 8px;
  border-radius: 5px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), transparent); /* 그라데이션 효과 */
}

.text-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.clickable {
  cursor: pointer;
}
</style>