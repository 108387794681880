<script>
import {formatNumber, openUrlInNewTab} from "@/utils";

export default {
  name: "MobileContentCard",
  methods: {formatNumber, openUrlInNewTab},
  props: [
    "thumbnailUrl",
    "uploadDate",
    "playCount",
    "likeCount",
    "commentCount",
    "shareCount",
    "saveCount",
  ],
  // 좋아요 / 댓글 / 공유 / 저장 / 업로드 날짜
}
</script>

<template>
  <v-row
      align="center"
  >
    <v-col cols="7">
      <v-img
          id="content-image"
          :src="thumbnailUrl"
          style="border-radius: 2%; aspect-ratio: 9 / 16;"
          class="clickable"
          cover
      >
        <v-container
            class="overlay-top"
        >
          <v-row no-gutters justify="end">
            <v-col cols="auto">
              {{ uploadDate }}
            </v-col>
          </v-row>
        </v-container>
        <v-container
            class="overlay-bottom"
        >
          <v-row no-gutters>
            <v-col cols="auto">
              <v-icon>
                mdi-play
              </v-icon>
            </v-col>
            <v-col cols="auto">
              {{ formatNumber(playCount) }}
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </v-col>
    <v-col cols="5">
      <v-card
          variant="flat"
      >
        <v-card-item class="px-0 py-0">
          <label
              for="followCount"
              class="text-text-secondary"
              style="font-size: 14px"
          >
            좋아요
          </label>
          <v-text-field
              id="followCount"
              variant="outlined"
              density="compact"
              base-color="text-secondary"
              readonly
              hide-details
          >
            {{ formatNumber(likeCount) }}
          </v-text-field>
        </v-card-item>
        <v-card-item class="px-0 py-0">
          <label
              for="followCount"
              class="text-text-secondary"
              style="font-size: 14px"
          >
            댓글
          </label>
          <v-text-field
              id="followCount"
              variant="outlined"
              density="compact"
              base-color="text-secondary"
              readonly
              hide-details
          >
            {{ formatNumber(commentCount) }}
          </v-text-field>
        </v-card-item>
        <v-card-item class="px-0 py-0">
          <label
              for="followCount"
              class="text-text-secondary"
              style="font-size: 14px"
          >공유
          </label>
          <v-text-field
              id="followCount"
              variant="outlined"
              density="compact"
              base-color="text-secondary"
              readonly
              hide-details
          >
            {{ formatNumber(shareCount) }}
          </v-text-field>
        </v-card-item>
        <v-card-item class="px-0 py-0">
          <label
              for="followCount"
              class="text-text-secondary"
              style="font-size: 14px"
          >저장
          </label>
          <v-text-field
              id="followCount"
              variant="outlined"
              density="compact"
              base-color="text-secondary"
              readonly
              hide-details
          >
            {{ formatNumber(saveCount) }}
          </v-text-field>
        </v-card-item>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped>
.overlay-top {
  position: absolute;
  top: 0;
  left: 50%;
  font-weight: bold;
  transform: translateX(-50%);
  color: white;
  padding: 8px;
  border-radius: 5px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), transparent); /* 그라데이션 효과 */
}

.overlay-bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  font-weight: bold;
  transform: translateX(-50%);
  color: white;
  padding: 8px;
  border-radius: 5px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent); /* 그라데이션 효과 */
}
</style>