<script>
import createApiService from "@/apiService";
import {useDisplay} from "vuetify";

const PageStatus = {
  PROCESSING: 'processing',
  SUCCESS: 'success',
  FAILURE: 'failure',
}
const ProcessType = {
  SUBSCRIBE: 'subscribe',
  DOWNGRADE: 'downgrade',
  UPGRADE: 'upgrade',
}

export default {
  name: "MembershipProcessPage",
  setup() {
    const { smAndDown, smAndUp } = useDisplay()
    return { smAndDown, smAndUp }
  },
  data: () => ({
    PageStatus,
    ProcessType,
    pageStatus: PageStatus.PROCESSING,
    successMessage: '처리가 완료되었습니다.',
    failureMessage: '처리 중 문제가 발생했습니다.',
    failureCTABtnText: '다시 시도하기',
  }),
  methods: {
    subscribePlan(plan, authKey, customerKey) {
      this.failureCTABtnText = '다시 멤버십 등록하기';
      this.api.post('/api/membership/subscription/start', {
        plan: plan,
        authKey: authKey,
        customerKey: customerKey
      }).then((response) => {
        switch (response.data.code) {
          case 'FAILURE':
            this.failureMessage = response.data.message;
            this.pageStatus = PageStatus.FAILURE;
            break;
          case 'SUCCESS':
            this.successMessage = '구독이 완료되었습니다!';
            this.pageStatus = PageStatus.SUCCESS;
            break;
        }
      }).catch(() => {
        this.pageStatus = PageStatus.FAILURE;
      })
    },

    downgradePlan(plan) {
      this.api.post('/api/membership/subscription/downgrade', {
        newPlan: plan,
      }).then((response) => {
        switch (response.data.code) {
          case 'FAILURE':
            this.failureMessage = response.data.message;
            this.pageStatus = PageStatus.FAILURE;
            break;
          case 'SUCCESS':
            this.successMessage = '다음 달부터 변경된 멤버십이 적용됩니다.';
            this.pageStatus = PageStatus.SUCCESS;
            break;
        }
      }).catch(() => {
        this.pageStatus = PageStatus.FAILURE;
      })
    },

    upgradePlan(plan) {
      this.api.post('/api/membership/subscription/upgrade', {
        newPlan: plan,
      }).then((response) => {
        switch (response.data.code) {
          case 'FAILURE':
            this.failureMessage = response.data.message;
            this.pageStatus = PageStatus.FAILURE;
            break;
          case 'SUCCESS':
            this.successMessage = '즉시 변경된 멤버십이 적용됩니다.';
            this.pageStatus = PageStatus.SUCCESS;
            break;
        }
      }).catch(() => {
        this.pageStatus = PageStatus.FAILURE;
      })
    },
  },
  created() {
    this.api = createApiService(this.$store, this.$router);
  },
  mounted() {
    let queryParams = new URLSearchParams(location.search);
    let type = queryParams.get('type')
    let plan = queryParams.get('plan')
    const customerKey = queryParams.get('customerKey');
    const authKey = queryParams.get('authKey');

    if (!type || !plan) {
      alert('잘못된 접근입니다.')
      this.$router.push('/membership/subscribe')
      return;
    }

    this.$router.replace({query: {}})

    switch (type) {
      case ProcessType.SUBSCRIBE: {
        if (!customerKey || !authKey) {
          alert('잘못된 접근입니다.')
          this.$router.push('/membership/subscribe')
          break;
        }
        this.subscribePlan(plan, authKey, customerKey)
        break;
      }
      case ProcessType.DOWNGRADE: {
        this.downgradePlan(plan)
        break;
      }
      case ProcessType.UPGRADE: {
        this.upgradePlan(plan)
        break;
      }
      default: {
        alert('잘못된 접근입니다.')
        this.$router.push('/membership/subscribe')
      }
    }
  }
}
</script>

<template>
  <v-container
      fluid
      class="align-center"
      style="height: 70vh"
      v-if="pageStatus === PageStatus.PROCESSING"
  >
    <v-container
        class="fill-height"
    >
      <v-container>
        <v-row align="center" justify="center">
          <v-progress-circular
              indeterminate
              :size="128"
              :width="8"
          ></v-progress-circular>
        </v-row>
        <v-row
            class="py-8 text-h6"
            align="center"
            justify="center"
        >
          요청 처리 중입니다.
        </v-row>
      </v-container>
    </v-container>
  </v-container>

  <v-container
      fluid
      class="px-5 pt-5 justify-center h-100"
      v-if="pageStatus === PageStatus.SUCCESS"
  >
    <v-row justify="center" align="center" class="h-100">
      <v-col align-self="center" :cols="smAndDown ? 12 : 5">
        <v-row align="center" justify="center">
          <v-sheet rounded="3">
            <v-card
                rounded="2"
                variant="flat"
            >
              <v-card-title class="font-weight-bold text-h6 text-center">
                <span class="text-blue-accent-2">
                  {{ successMessage }}
                </span>
              </v-card-title>
            </v-card>
            <v-container align="center">
              <v-img width="300" :src="require('@/assets/logo2.png')"></v-img>
            </v-container>
            <v-container
                class="pa-10"
            >
              <v-row
                  justify="center"
                  class="pb-4"
              >
                <v-btn color="primary" size="large" prepend-icon="mdi-rocket" to="/"
                >
                  숏부스트 사용하러 가기
                </v-btn>
              </v-row>
            </v-container>
          </v-sheet>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
  <v-container
      fluid
      class="px-5 pt-5 justify-center h-100"
      v-if="pageStatus === PageStatus.FAILURE"
  >
    <v-row justify="center" align="center" class="h-100">
      <v-col align-self="center" :cols="smAndDown ? 12 : 5">
        <v-row align="center" justify="center">
          <v-sheet rounded="3">
            <v-card
                rounded="2"
                variant="flat"
            >
              <v-card-title class="font-weight-bold text-h6 text-center">
                <span>
                  {{ failureMessage }}
                </span>
              </v-card-title>
            </v-card>
            <v-container align="center">
              <v-img width="300" :src="require('@/assets/fixing.png')"></v-img>
            </v-container>
            <v-container
                class="pa-10"
            >
              <v-row
                  justify="center"
                  class="pb-4"
              >
                <v-btn color="primary" size="large" prepend-icon="mdi-restore" to="/membership/subscribe"
                >
                  {{ failureCTABtnText }}
                </v-btn>
              </v-row>
            </v-container>
          </v-sheet>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>


<style scoped>

</style>