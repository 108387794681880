<script>
export default {
  name: "GoogleLogin",
  data () {
    return {
      appUrl: process.env.VUE_APP_URL
    }
  },
  methods: {
    sendAuthData() {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('code')) {
        window.opener.postMessage({ type: "googleAuthCode", code: urlParams.get('code') }, this.appUrl);
      }
      window.close();
    }
  },
  mounted() {
    this.sendAuthData();
  }
}
</script>

<template>
  <v-container fluid=true>
    <v-sheet>
      <v-container>
      </v-container>
    </v-sheet>
  </v-container>
</template>

<style scoped>

</style>