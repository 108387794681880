<script>
import {useDisplay} from "vuetify";
import createApiService from "@/apiService";
import AILoadingPage from "@/components/ai/AILoadingPage.vue";
import {openUrlInNewTab} from "@/utils";
import UsageLimitDialogCard from "@/components/ai/UsageLimitDialogCard.vue";
import UserInputDialog from "@/components/ai/UserInputDialog.vue";
const PageStatus = {
  PROCESSING: 'processing',
  SUCCESS: 'success',
  FAILURE: 'failure',
}

export default {
  name: "HookingmentAIPage",
  components: {UserInputDialog, UsageLimitDialogCard, AILoadingPage},
  setup() {
    const { smAndDown, smAndUp } = useDisplay()
    return { smAndDown, smAndUp }
  },
  data: () => ({
    PageStatus,
    pageStatus: PageStatus.PROCESSING,
    usageLimitDialog: false,
    inputDialog: false,
    uuid: null,
    pullingId: null,
    pullingCount: 0,
    maxPullingCount: 60,
    userInput: null,
    generatedHookingmentList: [],
  }),
  methods: {
    openUrlInNewTab,
    getGeneratedHookingment() {
      this.pullingCount++
      if (this.pullingCount > this.maxPullingCount) {
        this.pullingCount = 0;
        clearInterval(this.pullingId);
        alert("문제가 발생했습니다. 지속적으로 발생 시 서비스 센터에 문의해주세요.")
        this.$router.push('/?type=hookingment-ai');
      }
      this.api.get(`/api/ai/hookingment/${this.uuid}`)
          .then((response) => {
            if (response.data.data.status === 'COMPLETED') {
              this.generatedHookingmentList = response.data.data.contentList.map((item, index) => {
                return {
                  title: `후킹 멘트 (${index + 1})`,
                  hookingment: item.content,
                  reason: item.reason
                }
              })
              this.userInput = response.data.data.userInput;
              this.pageStatus = PageStatus.SUCCESS;
              clearInterval(this.pullingId);
            }
          })
          .catch(() => {
            this.pullingCount = 0;
            clearInterval(this.pullingId);
            alert("문제가 발생했습니다. 지속적으로 발생 시 서비스 센터에 문의해주세요.")
            this.$router.push('/?type=hookingment-ai');
          });
    },
    regenerate() {
      this.pageStatus = PageStatus.PROCESSING;
      const body = {
        topic: this.userInput,
        isRegenerated: true
      }
      this.api.post("/api/ai/hookingment", body)
          .then((response) => {
            switch (response.data.code) {
              case 'EXCEEDED_USAGE_LIMIT': {
                this.pageStatus = PageStatus.SUCCESS;
                this.usageLimitDialog = true;
                break;
              }
              case 'SUCCESS': {
                let uuid = response.data.data.uuid;
                this.$router.push({name: 'HookingmentAIPage', query: { "uuid": uuid }})
                break;
              }
              default: {
                alert("문제가 발생했습니다. 서비스 센터에 문의해주세요.")
                this.$router.push('/?type=hookingment-ai');
                break;
              }
            }
          }).catch(() => {
            alert("문제가 발생했습니다. 서비스 센터에 문의해주세요.")
          });
    },
    newTopic() {
      this.$router.push('/?type=hookingment-ai');
    }
  },
  watch: {
    '$route.query.uuid'(uuid) {
      if (uuid) {
        this.uuid = uuid;
        this.generatedHookingmentList = [];
        this.pageStatus = PageStatus.PROCESSING;
        this.userInput = null;
        this.pullingId = null;
        this.pullingCount = 0;
        this.pullingId = setInterval(() => {
          this.getGeneratedHookingment()
        }, 1000)
      }
    }
  },
  beforeCreate() {
    const uuid = this.$route.query.uuid;
    if (!uuid) {
      this.$router.push('/?type=hookingment-ai');
    }
  },
  created() {
    const uuid = this.$route.query.uuid;
    this.uuid = uuid;
    this.api = createApiService(this.$store, this.$router);
    this.pullingId = setInterval(() => {
      this.getGeneratedHookingment()
    }, 1000)
  },
  beforeUpdate() {
    const uuid = this.$route.query.uuid;
    if (!uuid) {
      this.$router.push('/?type=hookingment-ai');
    }
  },
  beforeUnmount() {
    console.log("beforeUnmount", this.pullingId)
    console.log("beforeUnmount", this.uuid)
    if (this.pullingId) {
      clearInterval(this.pullingId);
    }
  }
}
</script>

<template>
  <v-container
      :class="smAndUp ? 'px-10 pt-10 fill-height' : 'px-5 pt-5 fill-height'"
      v-if="pageStatus === PageStatus.PROCESSING"
      fluid="true"
      fill-height
  >
    <AILoadingPage/>
  </v-container>
  <v-container
      fluid="true"
      :class="smAndUp ? 'px-10 pt-10' : 'px-5 pt-5'"
      v-if="pageStatus === PageStatus.SUCCESS"
  >
    <v-row @click="openUrlInNewTab('https://bit.ly/3Wj9gDf')" class="clickable">
      <v-col v-if="smAndUp" cols="6">
        <v-img :src="require('@/assets/pc_banner.png')"></v-img>
      </v-col>
      <v-col v-else>
        <v-img :src="require('@/assets/mobile_banner.png')"></v-img>
      </v-col>
    </v-row>
    <v-row
        class="text-h5"
        align="center"
        justify="center"
    >
      <v-col v-if="smAndUp">
        숏부스트 AI가 만든 후킹멘트에요
      </v-col>
      <v-col v-else cols="auto">
        숏부스트 AI가 <br> 만든 후킹멘트에요
      </v-col>
      <v-col class="text-end">
        <v-btn color="primary" @click="inputDialog=true">
          입력값 보기
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="my-5"/>
    <v-row
        v-if="smAndDown"
        align="center"
        justify="center"
    >
      <v-col
          cols="12"
          v-for="hookingment in generatedHookingmentList"
          :key="hookingment"
          align-self="center"
      >
        <v-card
            variant="outlined"
            style="border-color: #9B9B9B;"
        >
          <v-card-title class="text-primary font-weight-bold my-3">
            # {{ hookingment.title }}
          </v-card-title>
          <v-card-text>
            주제 : {{ hookingment.hookingment }}
          </v-card-text>
          <v-card-text>
            추천 이유 : {{ hookingment.reason }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-row>
          <v-col cols="6">
            <v-btn color="text-secondary" size="large" block="true" @click="regenerate">
              같은 주제로 재생성
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn color="primary" size="large" block="true" @click="newTopic">
              다른 주제로 생성
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
        v-else
        align="center"
        justify="center"
        class="py-8"
    >
      <v-col
          cols="4"
          v-for="hookingment in generatedHookingmentList"
          :key="hookingment"
          align-self="center"
      >
        <v-card
            class="mb-5"
            variant="outlined"
            style="border-color: #EBEBEB;"
            height="30vh"
        >
          <v-card-title class="text-primary font-weight-bold my-3">
            # {{ hookingment.title }}
          </v-card-title>
          <v-card-text>
            주제 : {{ hookingment.hookingment }}
          </v-card-text>
          <v-card-text>
            추천 이유 : {{ hookingment.reason }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col align-self="center">
        <v-row justify="center">
          <v-col cols="8">
            <v-btn color="text-secondary" size="x-large" block="true"  @click="regenerate">
              같은 주제로 재생성
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="8">
            <v-btn color="primary" size="x-large" block="true" @click="newTopic">
              다른 주제로 생성
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
  <v-dialog
      v-model="usageLimitDialog"
      width="auto"
  >
    <UsageLimitDialogCard @close-usage-limit-dialog="usageLimitDialog=false"/>
  </v-dialog>
  <v-dialog
      v-model="inputDialog"
      :width="smAndUp ? '40vw' : '100vw'"
  >
    <UserInputDialog :user-input="userInput" @close-dialog="inputDialog=false"/>
  </v-dialog>
</template>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>