<script>
import {openUrlInNewTab} from "@/utils";
import {useDisplay} from "vuetify";

export default {
  name: "EnrollStartPage",
  data() {
    return {
      appUrl: process.env.VUE_APP_URL,
      facebookAppId: process.env.VUE_APP_FB_APP_ID,
    }
  },
  methods: {
    openUrlInNewTab,
    facebookAuth() {
      let url = `https://www.facebook.com/v17.0/dialog/oauth?client_id=${this.facebookAppId}&redirect_uri=${this.appUrl}/enroll-my-channel/callback&state=blah&response_type=token&scope=instagram_basic,instagram_manage_insights,pages_read_engagement,pages_show_list,business_management`;
      window.open(url, 'metaAccountEnroll', 'width=600, height=750, left=500, top=200');
    }
  },
  mounted() {
    window.addEventListener('message', event => {
      if (event.origin === this.appUrl && event.data.type === 'metaAccountEnrollAuthCode') {
        this.$router.push('/enroll-my-channel/select-account?accessToken=' + event.data.code)
      }
    }, false);
  },
  setup() {
    const {smAndDown, smAndUp} = useDisplay()
    return {smAndDown, smAndUp}
  },
}
</script>

<template>
  <v-container
      fluid
      class="px-5 pt-5 justify-center h-100"
  >
    <v-row align="center" justify="center" class="h-100 pb-10">
      <v-col cols="12">
        <v-row class="justify-center align-center">
          <v-card
              rounded="2"
              variant="flat"
              class="pa-10"
          >
            <v-card-title
                class="font-weight-bold text-h6"
                v-if="smAndDown"
            >
              <span class="text-blue-accent-2">페이스북 계정 연동</span>으로<br>
              내 인스타그램 분석을 <br>
              이용해보세요!
            </v-card-title>
            <v-card-title
                class="font-weight-bold text-h6"
                v-else
            >
              <span class="text-blue-accent-2">페이스북 계정 연동</span>으로<br>
              내 인스타그램 분석을 이용해보세요!
            </v-card-title>
            <v-card-subtitle
                v-if="smAndDown"
            >
            </v-card-subtitle>
            <v-card-subtitle
                v-else
            >
              서비스 이용을 위해서 페이스북 계정을 연동해야 합니다.
            </v-card-subtitle>
          </v-card>
        </v-row>
        <v-row class="justify-center pa-3">
          <v-card
              subtitle="시작하기 전에"
              title="가이드 확인하기"
              variant="elevated"
              width="350"
              @click="openUrlInNewTab('https://slashpage.com/shortboost/4z7pvx2k9gp9g2ek8653')"
          >
            <template v-slot:subtitle>
              시작하기 전에
            </template>
            <template v-slot:title>
              <div class="font-weight-bold">가이드북 확인하기</div>
            </template>
            <template v-slot:append>
              <v-icon icon="mdi-book-open-blank-variant"></v-icon>
            </template>
          </v-card>
        </v-row>
        <v-row class="justify-center pa-3">
          <v-card
              variant="elevated"
              width="350"
              @click="facebookAuth"
          >
            <template v-slot:subtitle>
              페이스북 계정으로
            </template>
            <template v-slot:title>
              <div class="font-weight-bold">인스타그램 연동하기</div>
            </template>
            <template v-slot:append>
              <v-icon icon="mdi-instagram" color="pink"></v-icon>
            </template>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>

</template>

<style scoped>

</style>